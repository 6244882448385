<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcom' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card class="box-card">
      <!-- 搜索框 -->
      <div style="margin-bottom: 20px;">
        <el-input
            placeholder="搜索角色名称"
            v-model="searchQuery"
            style="width: 400px;"
            @keyup.enter.native="fetchRoles"
            @clear="handleSearchClear"
            clearable
        >
          <el-button slot="append" icon="el-icon-search" @click="fetchRoles"></el-button>
        </el-input>
        <el-button type="primary" @click="showAddRoleDialog" style="margin-left: 20px;">新增角色</el-button>
      </div>

      <!-- 角色列表表格 -->
      <el-table
          :data="roles"
          border
          style="width: 100%"
          stripe
          highlight-current-row
      >
        <el-table-column prop="name" label="角色名称" sortable></el-table-column>
        <el-table-column prop="code" label="用户标识"></el-table-column>
        <el-table-column prop="create_time" label="创建时间"></el-table-column>
        <el-table-column prop="update_time" label="修改时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作" width="258">
          <template slot-scope="scope">
            <el-button size="mini" @click="showPermissionDialog(scope.row)">权限管理</el-button>
            <el-button size="mini" @click="showEditRoleDialog(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteRole(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
          :total="totalRoles"
          :page-size="pageSize"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      ></el-pagination>
    </el-card>

    <!-- 权限管理对话框 -->
    <el-dialog title="权限管理" :visible.sync="permissionDialogVisible" width="50%">
      <el-transfer
          filterable
          :data="permissionList"
          v-model="selectedPermissions"
          :titles="['可分配权限', '已分配权限']"
          :render-content="renderPermission"
          @change="handleTransferChange">
      </el-transfer>
      <div slot="footer" class="dialog-footer">
        <el-button @click="permissionDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateRolePermissions">确定</el-button>
      </div>
    </el-dialog>

    <!-- 新增角色对话框 -->
    <el-dialog title="新增角色" :visible.sync="roleDialogVisible" width="30%">
      <el-form :model="roleForm" ref="roleForm" label-width="80px">
        <el-form-item label="角色名称">
          <el-input v-model="roleForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="roleForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitRoleForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '', // 合并后的搜索条件
      roles: [],
      // 添加分页控制
      pageSize: 10,
      currentPage: 1,
      totalRoles: 0,
      roleDialogVisible: false, // 控制新增角色对话框的显示
      roleForm: {
        id: null, // 用于编辑时存储角色ID
        name: '',
        remark: ''
      },
      isEditMode: false, // 标识是否为编辑模式
      // 添加搜索条件
      searchFilters: {
        RoleName: '',
        RoleCode: ''
      },
      permissionDialogVisible: false, // 控制权限管理对话框的显示
      permissionList: [], // 存储权限列表
      selectedPermissions: [], // 穿梭框中选中的权限
      currentRole: null, // 当前选中的角色

      permissionsToAdd: [],
      permissionsToRemove: [],
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    // 获取角色列表
    fetchRoles() {
      const params = {
        Offset: this.currentPage,
        Limit: this.pageSize,
        RoleName: this.searchQuery,
      };
      this.$http.get('/v1/Role', { params }).then(({ data }) => {
        this.roles = data.data;
        this.totalRoles = data.count;
      });
    },
    // 处理分页大小变更
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // 重置为第一页
      this.fetchRoles(); // 刷新列表
    },

    // 处理当前页码变更
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchRoles(); // 刷新列表
    },
    showEditRoleDialog(role) {
      this.isEditMode = true;
      this.roleForm = { ...role };
      this.roleDialogVisible = true;
    },
    submitRoleForm() {
      if (this.isEditMode) {
        this.$http.put('/v1/Role', {
          role_id: this.roleForm.id,
          name: this.roleForm.name
        }).then(({ data }) => {
          if (data.state) {
            this.$message.success(data.msg);
            this.roleDialogVisible = false;
            this.fetchRoles();
          } else {
            this.$message.error(data.msg);
          }
        });
      } else {
        this.$http.post('/v1/Role', {
          name: this.roleForm.name
        }).then(({ data }) => {
          if (data.state) {
            this.$message.success(data.msg);
            this.roleDialogVisible = false;
            this.fetchRoles();
          } else {
            this.$message.error(data.msg);
          }
        });
      }
    },
    deleteRole(role) {
      this.$confirm('确认删除该角色吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete('/v1/Role', { data: { role_id: role.id } }).then(({ data }) => {
          if (data.state) {
            this.$message.success(data.msg);
            this.fetchRoles();
          } else {
            this.$message.error(data.msg);
          }
        });
      }).catch(() => {
        this.$message.info('删除操作已取消');
      });
    },
    // 显示新增角色对话框
    showAddRoleDialog() {
      this.isEditMode = false; // 设置为非编辑模式
      this.roleForm = { id: null, name: '', remark: '' }; // 重置表单
      this.roleDialogVisible = true; // 显示对话框
    },
    // 搜索框清除内容时的处理函数
    handleSearchClear() {
      this.searchQuery = ''; // 清空搜索条件
      this.fetchRoles(); // 刷新角色列表
    },
    // 显示权限管理对话框
    async showPermissionDialog(role) {
      this.currentRole = role;
      try {
        // 获取当前角色已分配的权限
        const assignedPermissionsResponse = await this.$http.get(`/v1/RolePermission?role_id=${role.id}`);
        let assignedPermissions = [];
        if (assignedPermissionsResponse.data.state) {
          assignedPermissions = assignedPermissionsResponse.data.data.permissions.map(p => p.permission_id);
        }

        // 获取所有权限列表
        const allPermissionsResponse = await this.$http.get('/v1/Permission?Offset=1&Limit=1000');
        let allPermissions = [];
        if (allPermissionsResponse.data.state) {
          allPermissions = allPermissionsResponse.data.data.map(p => ({
            key: p.permission_id,
            label: p.permission_name,
          }));
        }

        // 设置穿梭框中的权限列表和已选中的权限
        this.permissionList = allPermissions;
        this.selectedPermissions = assignedPermissions;
        this.permissionDialogVisible = true;
      } catch (error) {
        this.$message.error('获取权限列表失败');
      }
    },
    // 更新角色权限
    updateRolePermissions() {
      // 处理添加权限
      if (this.permissionsToAdd.length > 0) {
        this.addRolePermissions(this.permissionsToAdd);
      }
      // 处理删除权限
      if (this.permissionsToRemove.length > 0) {
        this.removeRolePermissions(this.permissionsToRemove);
      }
      // 在请求完成后清空待添加和待删除的权限数组
      this.permissionsToAdd = [];
      this.permissionsToRemove = [];
      // 关闭权限管理对话框
      this.permissionDialogVisible = false;
    },
    renderPermission(h, option) {
      return h('span', option.label);
    },
    // 处理穿梭框中权限移动的事件
    handleTransferChange(targetKeys, direction, moveKeys) {
      if (direction === 'left') {
        // 将移除的权限添加到待删除数组
        this.permissionsToRemove.push(...moveKeys);
        // 从待添加数组中移除（如果之前被添加过）
        this.permissionsToAdd = this.permissionsToAdd.filter(id => !moveKeys.includes(id));
      } else if (direction === 'right') {
        // 将添加的权限添加到待添加数组
        this.permissionsToAdd.push(...moveKeys);
        // 从待删除数组中移除（如果之前被移除过）
        this.permissionsToRemove = this.permissionsToRemove.filter(id => !moveKeys.includes(id));
      }
      // 更新已选中的权限列表，以保持UI同步
      this.selectedPermissions = targetKeys;
    },
    // 添加角色权限
    addRolePermissions(permissionIds) {
      const payload = {
        role_id: this.currentRole.id,
        permission_ids: permissionIds,
      };
      this.$http.post('/v1/RolePermission', payload).then(response => {
        if (response.data.state) {
          this.$message.success(response.data.msg);
          // 可以在这里更新UI或状态，如果需要的话
        } else {
          this.$message.error(response.data.msg);
        }
      });
    },

// 删除角色权限
    removeRolePermissions(permissionIds) {
      const payload = {
        role_id: this.currentRole.id,
        permission_ids: permissionIds,
      };
      this.$http.delete('/v1/RolePermission', { data: payload }).then(response => {
        if (response.data.state) {
          this.$message.success(response.data.msg);
          // 更新已分配权限列表
          this.selectedPermissions = this.selectedPermissions.filter(id => !permissionIds.includes(id));
        } else {
          this.$message.error(response.data.msg);
        }
      });
    },
  }
};
</script>

<style scoped>
/* 自定义样式 */
</style>