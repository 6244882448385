<template>
  <div class="container">
    <h1 class="title">亚马逊店铺找ASIN</h1>
    <div class="site-selection">
      <el-radio-group v-model="selectedSite">
        <el-radio-button label="1">美国</el-radio-button>
        <el-radio-button label="10">加拿大</el-radio-button>
        <el-radio-button label="11">墨西哥</el-radio-button>
        <el-radio-button label="7">英国</el-radio-button>
        <el-radio-button label="3">德国</el-radio-button>
        <el-radio-button label="4">法国</el-radio-button>
        <el-radio-button label="5">意大利</el-radio-button>
        <el-radio-button label="6">西班牙</el-radio-button>
        <el-radio-button label="2">日本</el-radio-button>
        <el-radio-button label="8">澳大利亚</el-radio-button>
        <el-radio-button label="9">巴西</el-radio-button>
      </el-radio-group>
    </div>
    <div class="search-input">
      <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入店铺ID，一行一个"
          v-model="searchText"
          style="margin-right: 30px"
      ></el-input>
      <el-button type="primary" @click="search" style="width: 100px" :loading="loading" :disabled="loading">搜索</el-button>
      <el-button type="success" @click="exportData" style="width: 100px" :loading="exportLoading" :disabled="loading">
        <i class="el-icon-download"></i> 导出
      </el-button>
    </div>
    <span v-if="!!store_count">共查询到ASIN总数： {{ store_count }}</span>
    <div class="search-result">
      <el-table :data="tableData" style="width: 100%" v-loading="loading" height="550">
        <el-table-column type="index" label="序号" width="70"></el-table-column>
        <el-table-column prop="merchant" label="商家ID" width="140"></el-table-column>
        <el-table-column prop="title_text" label="标题" width="280" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="asin_url" label="商品链接" show-overflow-tooltip>
          <template slot-scope="scope">
            <a :href="scope.row.asin_url" target="_blank">{{ scope.row.asin_url }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="data_asin" label="ASIN" show-overflow-tooltip></el-table-column>
        <el-table-column prop="offscreen_text" label="价格" show-overflow-tooltip></el-table-column>
        <el-table-column prop="aria_label_value" label="评价" show-overflow-tooltip></el-table-column>
        <el-table-column prop="data_asin" label="ASIN" show-overflow-tooltip></el-table-column>
        <el-table-column label="缩略图" width="100">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover">
              <img :src="scope.row.src_link" style="width: 100%; height: auto;" />
              <img slot="reference" :src="scope.row.src_link" style="width: 50px; height: 50px; cursor: pointer;" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      exportLoading: false, // 导出按钮loading状态
      selectedSite: '1',
      searchText: '',
      tableData: [],
      loading: false,
      store_count: ''
    };
  },
  methods: {
    async exportData() {
      if (this.searchText.trim() === '') {
        this.$message.warning('请填写ASIN');
        return;
      }
      const asinList = this.searchText
          .split('\n')
          .filter(line => line.trim() !== '');

      try {
        this.exportLoading = true;
        const response = await this.$http.post('/v1/ExportStoreTheAsinList', {
          tenant_id: Cookies.get("tenant_id"),
          user_id: Cookies.get("user_id"),
          user_name: Cookies.get("username"),
          community_user_name: Cookies.get("community_user_name"),
          community_user_phone: Cookies.get("community_user_phone"),
          selected_site: this.selectedSite,
          asin_list: asinList
        }, { responseType: 'blob' }); // 指定响应类型为blob

        if (response.data.type === 'application/json') {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = JSON.parse(e.target.result);
            this.$message.error(data.msg || '导出失败');
          };
          reader.readAsText(response.data);
        } else {
          // 处理下载
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ExportStoreTheAsinList.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error('导出异常:', error);
        this.$message.error('导出异常，请稍后重试');
      } finally {
        this.exportLoading = false;
      }
    },
    async search() {
      if (this.searchText.trim() === '') {
        this.$message.warning('请填写店铺ID');
        return;
      }
      const asinList = this.searchText
          .split('\n')
          .filter(line => line.trim() !== ''); // 过滤掉空行

      try {
        this.loading = true; // 发送请求前将 loading 设置为 true
        const response = await this.$http.post('/v1/GetStoreTheAsinList', {tenant_id: Cookies.get("tenant_id"),user_id: Cookies.get("user_id"),
          user_name: Cookies.get("username"), community_user_name: Cookies.get("community_user_name"),
          community_user_phone: Cookies.get("community_user_phone"), selected_site: this.selectedSite, asin_list: asinList });
        if (response.data.code === 200) {
          const countrySite = response.data.data.find(data => data.country_site)?.country_site || '';
          this.store_count = response.data.data.find(data => data.store_count)?.store_count || '';
          this.tableData = response.data.data
        } else {
          console.error('请求失败:', response.data.msg);
          this.$message.warning(response.data.msg);
        }
      } catch (error) {
        console.error('请求异常:', error);
        this.$message.error('请求异常，请稍后重试');
      } finally {
        this.loading = false; // 请求完成后将 loading 设置回 false
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.site-selection {
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  margin-bottom: 20px;
}

.search-input .el-input {
  flex: 1;
  margin-right: 10px;
}

.search-input .el-button {
  width: 100px;
}
</style>
