import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './plugins/element.js'
import { Message } from 'element-ui';
import './assets/assets/css/css.css'
import axios from 'axios';
import ElementUI from 'element-ui'; // 导入 elementUI
import 'element-ui/lib/theme-chalk/index.css'; // 导入 css
import 'default-passive-events'
import Loading from '@/components/Loading.vue'

import "echarts"
import ECharts from 'vue-echarts'

Vue.component('v-chart', ECharts) //Vue.2

//引入全部elementui组件
Vue.use(ElementUI);

Vue.prototype.$message = Message


//配置axios请求默认路径
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

// 设置图片上传地址
Vue.prototype.$images_url = "http://127.0.0.1:10240/"

// 设置后端请求接口
axios.defaults.baseURL = 'http://39.105.157.108:10240/'
// axios.defaults.baseURL = 'http://127.0.0.1:10240/'

// 设置抖音小红书等采集后端请求接口
Vue.prototype.$WeMediaConfig_URL = 'http://127.0.0.1:5000/'

// 创建一个新的 axios 实例，并设置 USER_INFO_URL 作为默认 baseURL
const userInfoAxios = axios.create({
  baseURL: 'http://www.dominet.cn/'
});

// 将新的 axios 实例添加到 Vue 原型中
Vue.prototype.$userInfoAxios = userInfoAxios;


//配置token拦截器
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})


Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.component('Loading', Loading)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
