<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcom' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card class="box-card">
      <!-- 搜索框和新增按钮 -->
      <div style="margin-bottom: 20px;">
        <el-input
            placeholder="搜索权限名称"
            v-model="searchQuery"
            style="width: 400px;"
            @keyup.enter.native="fetchPermissions"
            @clear="handleSearchClear"
            clearable
        >
          <el-button slot="append" icon="el-icon-search" @click="fetchPermissions"></el-button>
        </el-input>
        <el-button type="primary" @click="showAddPermissionDialog" style="margin-left: 20px;">新增权限</el-button>
      </div>

      <!-- 权限列表表格 -->
      <el-table
          :data="permissions"
          border
          style="width: 100%"
          stripe
          highlight-current-row
      >
        <el-table-column prop="permission_id" label="权限ID" sortable></el-table-column>
        <el-table-column prop="permission_name" label="权限名称" sortable></el-table-column>
        <el-table-column prop="create_time" label="创建时间" sortable></el-table-column>
        <el-table-column prop="update_time" label="修改时间" sortable></el-table-column>
        <el-table-column prop="remark" label="备注" sortable></el-table-column>
        <el-table-column label="操作" width="265">
          <template slot-scope="scope">
            <el-button size="mini" @click="showMenuPermissionDialog(scope.row)">菜单&权限</el-button>
            <el-button size="mini" @click="showEditPermissionDialog(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deletePermission(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
          :total="totalPermissions"
          :page-size="pageSize"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      ></el-pagination>
    </el-card>
    <!-- 菜单&权限对话框 -->
    <el-dialog
        :title="'菜单&权限'"
        :visible.sync="menuPermissionDialogVisible"
        width="20%"
        top="2vh"
        append-to-body
    >
      <div class="dialog-content" style="max-height: 800px; overflow-y: auto;"> <!-- 使用类来添加样式 -->
        <el-tree
            :data="allMenus"
            :props="{ children: 'children', label: 'name' }"
            node-key="id"
            show-checkbox
            default-expand-all
            :default-checked-keys="selectedMenus"
            ref="menuTree"
        >
          <template slot-scope="{ node, data }">
            <span>{{ data.parentName }} / {{ data.name }}</span>
          </template>
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menuPermissionDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateMenuPermissions">保存</el-button>
      </div>
    </el-dialog>

    <!-- 新增/编辑权限对话框 -->
    <el-dialog :title="isEditMode ? '编辑权限' : '新增权限'" :visible.sync="permissionDialogVisible" width="30%">
      <el-form :model="permissionForm" ref="permissionForm" label-width="80px">
        <el-form-item label="权限名称">
          <el-input v-model="permissionForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="permissionForm.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="permissionDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitPermissionForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
      permissions: [],
      pageSize: 10,
      currentPage: 1,
      totalPermissions: 0,
      permissionDialogVisible: false,
      permissionForm: {
        id: null,
        name: '',
        remark: '' // 新增备注属性
      },
      isEditMode: false,

      currentPermissionId: null, // 当前编辑的权限ID
      menuPermissionDialogVisible: false, // 菜单&权限对话框的显示状态
      allMenus: [], // 所有菜单数据
      selectedMenus: [], // 当前权限已关联的菜单ID
    };
  },
  created() {
    this.fetchPermissions();
  },
  methods: {
    fetchPermissions() {
      const params = {
        Offset: this.currentPage,
        Limit: this.pageSize,
        PermissionName: this.searchQuery
      };
      this.$http.get('/v1/Permission', { params }).then(({ data }) => {
        this.permissions = data.data;
        this.totalPermissions = data.count;
      });
    },
    // 处理分页大小变更
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // 重置为第一页
      this.fetchPermissions(); // 刷新列表
    },

    // 处理当前页码变更
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchPermissions(); // 刷新列表
    },
    showAddPermissionDialog() {
      this.isEditMode = false;
      this.permissionForm = { id: null, name: '' };
      this.permissionDialogVisible = true;
    },
    showEditPermissionDialog(permission) {
      this.isEditMode = true;
      // 现在包括备注在内的权限信息都会被回显到表单中
      this.permissionForm = {
        id: permission.permission_id, // 注意这里的id应该是permission_id
        name: permission.permission_name,
        remark: permission.remark
      };
      this.permissionDialogVisible = true;
    },

    submitPermissionForm() {
      let url = '/v1/Permission';
      let payload = {
        name: this.permissionForm.name,
        remark: this.permissionForm.remark // 包括备注信息
      };

      if (this.isEditMode) {
        // 如果是编辑模式，需要包括permission_id
        payload.permission_id = this.permissionForm.id;
      }

      // 根据编辑模式选择合适的HTTP方法
      const method = this.isEditMode ? 'put' : 'post'; // 根据要求，判断是编辑还是新增权限

      this.$http[method](url, payload).then(({ data }) => {
        if (data.state) {
          this.$message.success(data.msg);
          this.permissionDialogVisible = false;
          this.fetchPermissions();
        } else {
          this.$message.error(data.msg || '操作失败'); // 如果响应中没有msg，则显示默认错误消息
        }
      }).catch(error => {
        // 捕获请求过程中可能出现的异常
        this.$message.error(error.message || '请求失败');
      });
    },
    deletePermission(permission) {
      this.$confirm('确认删除该权限吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(`/v1/Permission`, { data: { permission_id: permission.permission_id } }).then(({ data }) => {
          if (data.state) {
            this.$message.success(data.msg);
            this.fetchPermissions();
          } else {
            this.$message.error(data.msg);
          }
        });
      }).catch(() => {
        this.$message.info('删除操作已取消');
      });
    },
    handleSearchClear() {
      this.searchQuery = '';
      this.fetchPermissions();
    },
    // 显示菜单权限对话框
    showMenuPermissionDialog(permission) {
      this.selectedMenus = [];
      this.currentPermissionId = permission.permission_id;

      // 先获取所有菜单
      this.fetchAllMenus().then(() => {
        // 然后获取当前权限已关联的菜单
        this.fetchPermissionMenus(this.currentPermissionId).then(associatedMenus => {
          // 清空已选中的菜单
          this.selectedMenus = [];

          // 打印返回的关联菜单，确保数据正确
          console.log('Associated Menus:', associatedMenus);

          // 设置已关联的菜单ID为selectedMenus
          this.selectedMenus = associatedMenus;

          // 打印selectedMenus数组，确保它只包含关联的菜单ID
          console.log('Selected Menus:', this.selectedMenus);

          // 在获取到已关联的菜单后显示对话框
          this.menuPermissionDialogVisible = true;
        });
      }).catch(error => {
        this.$message.error('请求错误：' + error.message);
      });
    },
    // 获取所有顶级菜单及其子菜单
    fetchAllMenus() {
      return new Promise((resolve, reject) => {
        this.$http.get('/v1/GetMenuAll').then(response => {
          if (response.data.state) {
            // 遍历顶级菜单，将子菜单与顶级菜单名称关联起来
            let menusWithParentName = [];
            response.data.data.forEach(topMenu => {
              if (topMenu.children && topMenu.children.length > 0) {
                // 为每个子菜单添加顶级菜单名称
                topMenu.children = topMenu.children.map(subMenu => ({
                  ...subMenu,
                  parentName: topMenu.name // 添加顶级菜单名称作为属性
                }));
                menusWithParentName = menusWithParentName.concat(topMenu.children);
              }
            });
            this.allMenus = menusWithParentName;
            resolve();
          } else {
            reject(new Error('获取菜单数据失败'));
          }
        }).catch(error => {
          reject(error);
        });
      });
    },

    // 获取当前权限已关联的所有子菜单
    fetchPermissionMenus(permissionId) {
      return new Promise((resolve, reject) => {
        this.$http.get(`/v1/MenuPermission?permission_id=${permissionId}`).then(response => {
          if (response.data.state) {
            // 初始化一个空数组来存储子菜单的id
            let associatedMenus = [];
            // 遍历返回的菜单数据
            response.data.data.forEach(menu => {
              // 检查每个顶级菜单是否有子菜单
              if (menu.children && menu.children.length > 0) {
                // 如果有子菜单，将子菜单的id添加到associatedMenus数组中
                menu.children.forEach(subMenu => {
                  associatedMenus.push(subMenu.id);
                });
              }
            });
            // 解析包含所有子菜单id的数组
            resolve(associatedMenus);
          } else {
            reject(new Error('获取权限关联的菜单数据失败：' + response.data.msg));
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 更新权限与菜单的关联关系
    updateMenuPermissions() {
      // 获取选中的菜单ID
      const checkedKeys = this.$refs.menuTree.getCheckedKeys();

      // 发送请求更新权限与菜单的关联关系
      const payload = {
        permission_id: this.currentPermissionId,
        menu_ids: checkedKeys, // 这里假设所有选中的菜单都是子菜单
        submenu_ids: checkedKeys // 这里假设所有选中的菜单都是主菜单
      };

      this.$http.put('/v1/MenuPermission', payload).then(response => {
        if (response.data.state) {
          this.$message.success('菜单权限更新成功');
          this.menuPermissionDialogVisible = false;
          this.fetchPermissions(); // 更新权限列表
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.$message.error('菜单权限更新失败');
      });
    },
  }
};
</script>

<style scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* 垂直居中 */
  margin-bottom: 50px; /* 底部外边距，根据需要调整 */
}
</style>