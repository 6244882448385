<template>
  <div class="permission-query">
    <el-table :data="permissionData" border>
      <el-table-column prop="permission_name" label="会员等级" width="120"></el-table-column>
      <el-table-column label="大数据选品-是否可以访问大数据选品页面" width="180">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.can_access_page" @change="updatePermission(scope.row)"></el-switch>
        </template>
      </el-table-column>
<!--      <el-table-column label="大数据选品-是否可以默认条件查询">-->
<!--        <template slot-scope="scope">-->
<!--          <el-switch v-model="scope.row.can_query_default" @change="updatePermission(scope.row)"></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="大数据选品-是否可以筛选查询">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.can_query_filter" @change="updatePermission(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="大数据选品-是否可以导出数据">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.can_export" @change="updatePermission(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="工具箱-商标注册查询工具-查询权限">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.tools_brand" @change="updatePermission(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="工具箱-亚马逊ASIN找店铺-查询权限">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.tools_asin_the_store" @change="updatePermission(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="工具箱-亚马逊店铺找ASIN-查询权限">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.tools_store_the_asin" @change="updatePermission(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="产品收藏权限">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.product_collect" @change="updatePermission(scope.row)"></el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      permissionData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  methods: {
    fetchPermissionData() {
      this.$http.get(`/v1/PermissionResource?page=${this.currentPage}&per_page=${this.pageSize}`)
          .then(response => {
            this.permissionData = response.data.data;
            this.total = response.data.count;
          })
          .catch(error => {
            console.error(error);
          });
    },
    updatePermission(row) {
      const data = {
        id: row.id,
        can_access_page: row.can_access_page,
        can_query_default: row.can_query_default,
        can_query_filter: row.can_query_filter,
        can_export: row.can_export,
        tools_brand: row.tools_brand,
        tools_asin_the_store: row.tools_asin_the_store,
        tools_store_the_asin: row.tools_store_the_asin,
        product_collect: row.product_collect
      };

      this.$http.put('/v1/PermissionResource', data)
          .then(response => {
            if (response.data.code === 200) {
              this.$message.success('更新成功');
            } else {
              this.$message.error('更新失败');
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('更新失败');
          });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchPermissionData();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchPermissionData();
    }
  },
  created() {
    this.fetchPermissionData();
  }
};
</script>

<style scoped>
.permission-query {
  margin: 20px;
}

.el-table {
  margin-bottom: 20px;
}

.el-pagination {
  text-align: right;
}

.el-switch {
  display: block;
  margin: 0 auto;
}
</style>
