<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcom' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SalesDataReport' }">销售报表管理</el-breadcrumb-item>
      <el-breadcrumb-item>销售数据报表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 统计数据卡片 -->
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div style="text-align: center; margin: 20px 0">
            <el-statistic title="销售总价" :value="totalSalesAmount"></el-statistic>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div style="text-align: center; margin: 20px 0">
            <el-statistic title="销售总数" :value="totalSalesVolume"></el-statistic>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 销售数据报表列表 -->
    <el-card class="box-card" style="margin-top: 20px;">
      <el-table
          :data="salesReportList"
          border
          stripe
          highlight-current-row
          style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="artwork_id" label="艺术品ID"></el-table-column>
        <el-table-column prop="artwork_name" label="艺术品名称"></el-table-column>
        <el-table-column prop="total_sales" label="销售总价"></el-table-column>
        <el-table-column prop="total_quantity" label="销售总数"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "SalesReportManagement",
  data() {
    return {
      salesReportList: [], // 销售报表列表数据
      totalSalesAmount: 0, // 销售总价
      totalSalesVolume: 0, // 销售总数
      loading: false // 数据请求加载状态
    };
  },
  created() {
    this.getSalesReports() // 组件创建时获取销售报表
  },
  methods: {
    getSalesReports() {
      // 发起获取销售报表的请求
      this.loading = true;
      this.$http.get('/v1/SalesDataReport').then(response => {
        const { data } = response;
        if (data.state && data.code === 200) {
          // 分别设置销售总价和销售总数用于统计展示
          this.totalSalesAmount = data.data.total_sales_amount;
          this.totalSalesVolume = data.data.total_sales_volume;
          // 设置销售报表列表数据
          this.salesReportList = data.data.artwork_sales_details;
        } else {
          this.$message.error('获取销售数据报表失败');
        }
      }).catch(() => {
        this.$message.warning('网络错误，请稍后重试');
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.box-card {
  margin-bottom: 20px;
}
</style>