<template>
  <div  v-loading="loading">
    <!-- 搜索模块 -->
    <div class="search-module content-container">
      <div class="search-container">
        <el-select v-model="searchForm.country_site" placeholder="国家站点">
          <el-option label="美国" value="us"></el-option>
          <el-option label="德国" value="de"></el-option>
          <el-option label="法国" value="fr"></el-option>
          <el-option label="英国" value="uk"></el-option>
          <el-option label="西班牙" value="es"></el-option>
          <el-option label="意大利" value="it"></el-option>
          <el-option label="加拿大" value="ca"></el-option>
          <el-option label="日本" value="jp"></el-option>
        </el-select>
        <el-input v-model="searchForm.asin" placeholder="ASIN" clearable></el-input>
        <el-input v-model="searchForm.brandName" placeholder="品牌名称" clearable></el-input>
        <el-input v-model="searchForm.sellerName" placeholder="卖家名称" clearable></el-input>
        <el-button type="primary" @click="fetchProducts"><i class="el-icon-search"></i>搜索</el-button>
      </div>
    </div>
    <div>
      <el-select v-model="sortord" placeholder="排序规则" @change="fetchProducts">
        <el-option label="月销量从高到低" value="salesCount_desc"></el-option>
        <el-option label="评论数从高到低" value="reviews_desc"></el-option>
        <el-option label="价格从高到低" value="price_desc"></el-option>
        <el-option label="上架时间从近到远" value="firstAvailable_desc"></el-option>
        <el-option label="评论数从低到高" value="reviews_order"></el-option>
        <el-option label="月销量从低到高" value="salesCount_order"></el-option>
        <el-option label="价格从低到高" value="price_order"></el-option>
        <el-option label="上架时间从远到近" value="firstAvailable_order"></el-option>
      </el-select>

      <!-- 导出按钮 -->
      <el-button @click="showExportPopover = true" icon="el-icon-download" style="margin-right: 20px">导出</el-button>

      <!-- 导出弹出框 -->
      <el-popover v-model="showExportPopover" placement="bottom" width="300" trigger="manual">
        <div>
          <el-switch v-model="downloadImg" active-text="导出主图（加载速度慢）"></el-switch>
          <el-tooltip effect="dark" content="导出当前条件筛选的收藏数据" placement="top">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
        <div style="text-align: right; margin-top: 10px;">
          <el-button size="mini" type="text" @click="showExportPopover = false">取消</el-button>
          <el-button type="primary" size="mini" @click="exportData" :loading="exportLoading">确认导出</el-button>
        </div>
      </el-popover>
    </div>
    <el-divider></el-divider>
    <el-checkbox v-model="selectAll" @change="handleSelectAllChange"  style="margin-right: 10px;">全选</el-checkbox>
    <el-button type="danger" size="mini" @click="handleDeleteFavorites" :disabled="selectedProducts.length === 0">删除收藏</el-button>
    <!-- 视图切换按钮 -->
    <el-radio-group v-model="viewMode" size="mini" style="margin-left: 30px">
      <el-radio-button label="list">
        <i class="el-icon-menu"></i> 列表
      </el-radio-button>
      <el-radio-button label="card">
        <i class="el-icon-s-grid"></i> 卡片
      </el-radio-button>
    </el-radio-group>

    <el-divider></el-divider>

    <!-- 列表视图 -->
    <el-table v-if="viewMode === 'list'" :data="products" style="width: 100%">
      <el-table-column label="缩略图" width="150">
        <template slot-scope="scope">
          <el-popover placement="right" trigger="hover">
            <img :src="scope.row.mainImage" alt="large image" style="width: 400px; height: 400px;" />
            <img slot="reference" :src="scope.row.mainImage" alt="thumbnail" width="100" height="100" />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="asin" label="ASIN" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.asin }}</span>
          <a :href="scope.row.asin_url" target="_blank">
            <i class="el-icon-link" style="color: #007bff"></i>
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="brandName" label="品牌" show-overflow-tooltip></el-table-column>
      <el-table-column label="价格">
        <template slot-scope="scope">
          ${{ scope.row.price}}
        </template>
      </el-table-column>
      <el-table-column label="进货成本(￥)" prop="purchase_cost">
      </el-table-column>
      <el-table-column label="运费(￥)" prop="shipping_cost">
      </el-table-column>
      <el-table-column label="佣金">
        <template slot-scope="scope">
          ${{ (scope.row.price * 0.15).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column label="其他费率">
        <template>0%</template>
      </el-table-column>
      <el-table-column label="利润">
        <template slot-scope="scope">
          ￥{{ calculateProfit(scope.row).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column label="利润率">
        <template slot-scope="scope">
          {{ calculateProfitRate(scope.row).toFixed(2) }}%
        </template>
      </el-table-column>
      <el-table-column prop="dimensions" label="产品规格" show-overflow-tooltip></el-table-column>
      <el-table-column label="仓储时间" width="120">
        <template>1个月</template>
      </el-table-column>
      <el-table-column prop="created_time" label="收藏时间"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">修改</el-button>
          <el-button size="mini" type="danger" @click="handleDeleteFavorite(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="product-card">
      <el-card v-for="product in products" :key="product.id" :id="'card-' + product.id" v-if="viewMode === 'card'" >
        <div style="position: relative;">
          <el-checkbox v-model="product.checked" @change="handleSelectChange"></el-checkbox>
        </div>
        <div class="thumbnail" style="display: flex; justify-content: center; align-items: center;">
          <el-tooltip :id="`tooltip-${product.id}`" placement="right" :visible-arrow="false" :offset="10">
            <img :src="product.mainImage" alt="thumbnail" width="110" height="110" />
            <!--            <img :src="product.mainImage.slice(0, -16) + '._AC_SS100_.jpg'"-->
            <!--                 alt="thumbnail"-->
            <!--                 width="100"-->
            <!--                 height="100"-->
            <!--                 @error="onImageError" />-->
            <template #content>
              <img :src="product.mainImage" alt="large image" style="width: 400px; height: 400px;" />
            </template>
          </el-tooltip>
        </div>
        <div class="title">
          <el-tooltip :content="product.title" placement="top">
            <span @click="goToAsinUrl(product.asin_url)" @mouseover="highlightTitle" @mouseout="unhighlightTitle" ref="titleRef" class="title-text" :class="{ 'highlight': isHighlighted }">{{ product.title }}</span>
          </el-tooltip>
        </div>
        <div class="rating">
          <el-rate :value="product.star" disabled class="rate"></el-rate>
          <span class="reviews">{{ product.reviews  | formatNumber }}</span>
        </div>
        <div>
          <div class="price reviews" >${{ product.price }}</div>
          <div class="price a_row" v-if="product.location === 'US'">卖家所在地：美国</div>
          <div class="price a_row" v-else-if="product.location === 'CN'">卖家所在地：中国</div>
          <div class="price a_row" v-else-if="product.location === 'CA'">卖家所在地：加拿大</div>
          <div class="price a_row" v-else-if="product.location === 'JP'">卖家所在地：日本</div>
          <div class="price a_row" v-else-if="product.location === 'ES'">卖家所在地：西班牙</div>
          <div class="price a_row" v-else-if="product.location === 'GB'">卖家所在地：英国</div>
          <div class="price a_row" v-else-if="product.location === 'DE'">卖家所在地：德国</div>
        </div>
        <div class="asin">
          ASIN: {{ product.asin }}
          <el-button type="text" size="mini" @click="copyAsin(product.asin)">复制</el-button>
        </div>
        <div class="rating card_title-text">
          <span class="seller">卖家: {{ product.sellerName }}</span>
        </div>
        <div class="rating card_title-text">
          <span class="a_row">品牌: {{ product.brandName }}</span>
        </div>
        <div>
          <el-tag size="mini" style="margin-right: 10px;" v-if="product.shippingType === 1">FBA</el-tag>
          <el-tag size="mini" style="margin-right: 10px;" v-else-if="product.shippingType === 2">FBM</el-tag>
          <el-tag size="mini" style="margin-right: 10px;">{{ product.sellerFollow }}个跟卖</el-tag>
          <el-tag size="mini" style="margin-right: 10px;">{{ product.subAsinCount }}个变体</el-tag>
        </div>
        <div class="info-box">
          <div>子ASIN近30天销量: {{ product.boughtNum }}</div>
          <div>父ASIN月销量: {{ product.salesCount | formatNumber }}</div>
          <div>父ASIN月销售额: {{ product.sales | formatNumber }}</div>
          <el-tooltip :content="product.firstRankName" placement="top">
            <div class="card_title-text"><el-tag size="mini"># {{ product.firstRank }}</el-tag> {{ product.firstRankName }}</div>
          </el-tooltip>
          <el-tooltip :content="product.otherRankName" placement="top">
            <div class="card_title-text"><el-tag size="mini"># {{ product.otherRank }}</el-tag> {{ product.otherRankName }}</div>
          </el-tooltip>
        </div>
        <div class="listing-date card_title-text">上架时间: {{ product.firstAvailable }}</div>
        <div class="profit-margin card_title-text">毛利率: {{ product.profit }}%</div>
        <div class="fba-fee card_title-text">FBA运费: ${{ product.fba }}</div>
        <div class="size-type card_title-text">尺寸类型:  {{ product.size }}</div>
        <div class="weight card_title-text">重量: {{ product.weight }}lb</div>
        <el-tooltip :content="product.dimensions" placement="top">
          <div class="volume card_title-text">体积: {{ product.dimensions }}</div>
        </el-tooltip>
        <el-divider></el-divider>
        <el-popover
            placement="top-start"
            title="五点描述"
            width="500"
            trigger="click">
          <ul>
            <el-divider content-position="center">五点</el-divider>
            <li v-for="(item, index) in fivePointList" :key="index">{{ item }}</li>
          </ul>
          <ul>
            <el-divider content-position="center">描述</el-divider>
            <span class="a_row">{{ description }}</span>
          </ul>
          <el-button style="margin-right: 20px" size="mini" type="primary" slot="reference" @click="fetchDescription(product)">五点描述</el-button>
        </el-popover>
        <el-popover
            placement="top-start"
            title="品牌商标信息"
            width="500"
            trigger="click">
          <div v-if="brandInfo" class="brand-info-wrapper">
            <div class="brand-info-item">
              <span class="brand-info-label">品牌名称:</span>
              <span class="brand-info-value">{{ brandInfo.brandName }}</span>
            </div>
            <div class="brand-info-item">
              <span class="brand-info-label">国家/地区:</span>
              <span class="brand-info-value">
        <i class="el-icon-location"></i>
        {{ brandInfo.country }}
      </span>
            </div>
            <div class="brand-info-item">
              <span class="brand-info-label">商标状态:</span>
              <span class="brand-info-value">
        <span v-if="brandInfo.status === '死标'">
          <i class="el-icon-circle-close" style="color: red;"></i>
          {{ brandInfo.status }}
        </span>
        <span v-else-if="brandInfo.status === 'TM'">
          <i class="el-icon-tm" style="color: blue;"></i>
          {{ brandInfo.status }}
        </span>
        <span v-else-if="brandInfo.status === 'R'">
          <i class="el-icon-r" style="color: green;"></i>
          {{ brandInfo.status }}
        </span>
        <span v-else>
          <i class="el-icon-question" style="color: gray;"></i>
          {{ brandInfo.status || '无品牌' }}
        </span>
      </span>
            </div>
            <div class="brand-info-item">
              <el-tooltip effect="dark" placement="top">
                <div slot="content" v-html="tooltipContent"></div>
                <i class="el-icon-question"></i>
              </el-tooltip>
              <span class="brand-info-label">尼斯分类:</span>
              <div class="nice-class-wrapper">
                <el-tag v-for="item in brandInfo.niceClass" :key="item" size="small" style="margin-right: 5px;">
                  {{ item }}
                </el-tag>
              </div>
            </div>
          </div>
          <div v-else class="no-brand-info">
            <p>品牌商标信息查询中...</p>
          </div>
          <el-button class="brand-info-button" size="mini" type="primary" slot="reference" @click="look_brand(product)">品牌商标查询</el-button>
        </el-popover>
      </el-card>
    </div>
    <div>
      <el-backtop></el-backtop>
    </div>
    <div>
      <!-- 分页 -->
      <el-pagination
          :total="total"
          :page-size="limit"
          :page-sizes="[50, 100, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          class="pagination-right"
      >
      </el-pagination>

      <!-- 修改弹框 -->
      <el-dialog title="修改产品信息" :visible.sync="editDialogVisible" width="50%">
        <el-form :model="editForm" label-width="120px">
          <el-radio-group v-model="editMode" style="margin-bottom: 20px;" size="mini">
            <el-radio-button label="FBA">FBA</el-radio-button>
            <el-radio-button label="FBM">FBM</el-radio-button>
          </el-radio-group>

          <!-- FBA模式 -->
          <template v-if="editMode === 'FBA'">
            <el-form-item label="汇率" prop="exchangeRate" size="mini">
              <el-input v-model.number="editForm.exchange_rate"><template slot="append">USD / CNY</template></el-input>
            </el-form-item>
            <el-form-item label="价格" prop="price" size="mini">
              <el-tooltip effect="dark" content="售价" placement="top">
              <el-input v-model.number="editForm.custom_price" style="width: 180px;"><template slot="prepend">￥</template></el-input>
              </el-tooltip>
              <span style="margin-left: 10px;">$ {{ (editForm.custom_price / editForm.exchangeRate).toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="采购成本" prop="purchaseCost" size="mini">
              <el-tooltip effect="dark" content="产品单件成本，包括人工、材料和管理费用（默认为售价的 20%）" placement="top">
                <el-input v-model.number="editForm.purchase_cost.toFixed(2)" style="width: 180px;"><template slot="prepend">￥</template></el-input>
              </el-tooltip>
              <span style="margin-left: 10px;">$ {{ (editForm.purchase_cost / editForm.exchangeRate).toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="头程运费" prop="shippingCost" size="mini">
              <el-tooltip effect="dark" content="默认￥60/kg" placement="top">
              <el-input v-model.number="editForm.shipping_cost"><template slot="prepend">￥</template><template slot="append">/KG</template></el-input>
              </el-tooltip>
            </el-form-item>
<!--            <el-form-item label="FBA费用" prop="fba" size="mini">-->
<!--              <el-tooltip effect="dark" content="此产品的亚马逊打包和运输的估算的总费用" placement="top">-->
<!--              <el-input v-model.number="editForm.fba_fee"><template slot="prepend">$</template></el-input>-->
<!--              </el-tooltip>-->
<!--            </el-form-item>-->
            <el-form-item label="重量" prop="weight" size="mini">
              <el-tooltip effect="dark" content="产品和包装的总重量" placement="top">
              <el-input v-model.number="editForm.custom_weight.toFixed(2)" @input="updateWeight">
                <template slot="append">自动将 磅 转换为 克</template>
              </el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="预估仓储时间" prop="storageTime" size="mini">
              <el-tooltip effect="dark" content="产品在亚马逊仓库的存放时长" placement="top">
              <el-input v-model="editForm.estimated_storage_time"><template slot="append">个月</template></el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="仓储费用" size="mini">
              <el-tooltip effect="dark" content="基于产品尺寸参数估算的亚马逊月仓储费用 1-9 10-12月 每月每立方英尺的仓储费用(美元)" placement="top">
              </el-tooltip>
              <span>1月-9月$ 0.18 / 10月-12月$0.46</span>
            </el-form-item>
            <el-form-item label="平台佣金" prop="commission" size="mini">
              <el-tooltip effect="dark" content="亚马逊的通道费，基于售价" placement="top">
              <el-input v-model.number="editForm.platform_commission" style="width: 180px;"><template slot="append">%</template></el-input>
              </el-tooltip>
              <span style="margin-left: 10px;">$ {{ ((editForm.custom_price * editForm.platform_commission / 100) / editForm.exchange_rate).toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="其他成本" prop="otherCost" size="mini">
              <el-tooltip effect="dark" content="还未计入的其它费用" placement="top">
              <el-input v-model.number="editForm.other_costs"><template slot="append">%</template></el-input>
              </el-tooltip>
            </el-form-item>
            <el-row class="row-group">
              <el-col :span="12">
                <el-form-item label="1-9月净利润" size="mini">
                  <el-input v-model="editForm.netProfit1to9" disabled><template slot="append">￥</template></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="10-12月净利润" size="mini">
                  <el-input v-model="editForm.netProfit10to12" disabled><template slot="append">￥</template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-group">
              <el-col :span="12">
                <el-form-item label="1-9月净利润率" size="mini">
                  <el-input v-model="editForm.netProfitRate1to9" disabled><template slot="append">%</template></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="10-12月净利润率" size="mini" label-width="135px">
                  <el-input v-model="editForm.netProfitRate10to12" disabled><template slot="append">%</template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <!-- FBM模式 -->
          <template v-else>
            <el-form-item label="汇率" prop="exchangeRate" size="mini">
              <el-input v-model.number="editForm.exchange_rate"><template slot="append">USD / CNY</template></el-input>
            </el-form-item>
            <el-form-item label="价格" prop="price" size="mini">
              <el-tooltip effect="dark" content="售价" placement="top">
              <el-input v-model.number="editForm.custom_price" style="width: 180px;"><template slot="prepend">￥</template></el-input>
              </el-tooltip>
              <span style="margin-left: 10px;">$ {{ (editForm.custom_price / editForm.exchange_rate).toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="采购成本" prop="purchaseCost" size="mini">
              <el-tooltip effect="dark" content="产品单件成本，包括人工、材料和管理费用（默认为标价的 20%)" placement="top">
              <el-input v-model.number="editForm.purchase_cost.toFixed(2)" style="width: 180px;"><template slot="prepend">￥</template></el-input>
              </el-tooltip>
              <span style="margin-left: 10px;">$ {{ (editForm.purchase_cost / editForm.exchange_rate).toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="FBM运费" prop="fbmShippingCost" size="mini">
              <el-input v-model.number="editForm.fbm_shipping_fee"><template slot="prepend">￥</template></el-input>
            </el-form-item>
            <el-form-item label="平台佣金" prop="commission" size="mini">
              <el-tooltip effect="dark" content="亚马逊的通道费，基于售价" placement="top">
              <el-input v-model.number="editForm.platform_commission" style="width: 180px;"><template slot="append">%</template></el-input>
              </el-tooltip>
              <span style="margin-left: 10px;">$ {{ ((editForm.custom_price * editForm.platform_commission / 100) / editForm.exchange_rate).toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="其他成本" prop="otherCost" size="mini">
              <el-tooltip effect="dark" content="还未计入的其它费用" placement="top">
              <el-input v-model.number="editForm.other_costs"><template slot="append">%</template></el-input>
              </el-tooltip>
            </el-form-item>
            <el-row class="row-group">
              <el-col :span="12">
                <el-form-item label="净利润" size="mini">
                  <el-input v-model="editForm.netProfit" disabled><template slot="append">￥</template></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="净利润率" size="mini">
                  <el-input v-model="editForm.netProfitRate" disabled><template slot="append">%</template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>

</template>
<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      viewMode: 'list',
      editMode: 'FBA',
      editDialogVisible: false,
      editForm: {
        country_site: "",
        userid: Cookies.get("user_id"),
        tenant_id: Cookies.get("tenant_id"),
        favorite_id: '',
        exchangeRate: 7.2,
        price: 0,
        exchange_rate: 0,  // sql汇率
        custom_weight: 0,  // sql自定义重量（克）
        purchase_cost: 0,  // sql采购成本
        fba_fee: 0,  // sql fba成本
        custom_price: 0,  // sql售价-人民币
        estimated_storage_time: 0, // sql预估仓储时间
        platform_commission: 0, // sql 平台佣金%
        fbm_shipping_fee: 0, // sql fbm费用
        other_costs: 0,  // sql其他费用%
        shipping_cost: 0, // sql头程运费
        purchaseCost: 0,  // 采购成本
        shippingCost: 60, // 用于计算的头程运费(人民币)
        fba: 0,
        volume: '',
        weight: 0,
        sizeType: '',
        storageTime: 1,
        storageFee: 0.18,
        commission: 15,
        otherCost: 0,
        netProfit: 0,
        netProfitRate: 0,
        fbmShippingCost: 0,
        weightInGrams: 0,    // 重量(克)
      },
      selectAll: false, // 全选状态
      selectedProducts: [], // 选中的产品
      tooltipContent: "1.化学品<br>2.颜料、清漆、染料<br>3.化妆品、肥皂<br>4.润滑剂<br>5.工业用燃料和照明材料<br>6.金属材料<br>7.机器、机床、电动工具<br>8.手工工具和器具<br>9.科学仪器<br>10.医疗器械<br>11.照明、加热、蒸汽发生、烹饪、冷藏、干燥、通风、供水和卫生设备<br>12.车辆<br>13.武器、弹药<br>14.贵重金属制品和钟表<br>15.乐器<br>16.纸制品和办公用品<br>17.橡胶、塑料制品<br>18.皮革和模仿皮革制品<br>19.非金属建筑材料<br>20.家具、镜子、相框<br>21.家用或厨房用具和容器<br>22.绳索和纺织品<br>23.纱线和线<br>24.布料<br>25.服装<br>26.花边和刺绣、饰带和纽扣<br>27.地毯、地垫、席子、油毡、墙纸<br>28.游戏、玩具、体育用品<br>29.肉、鱼、家禽和野味、肉汤、腌制、冻干和烹调的水果和蔬菜、果冻、果酱、蛋、奶和乳制品<br>30.咖啡、茶、可可、糖、米、木薯、西米、咖啡代用品、面粉和谷类制品、面包、糕点和糖果、冰淇淋<br>31.农业、园艺、林业产品<br>32.啤酒、矿泉水和其他无酒精饮料、果汁和其他饮料<br>33.酒精饮料<br>34.烟草、烟具",
      // 品牌商标信息
      brandInfo: null,
      // 获取用户返回信息
      userInfo: {
        user_id: '',
        user_login: '',
        user_email: '',
        error: ''
      },
      // 列表数据导出
      showExportPopover: false,
      downloadImg: false,
      exportLoading: false,

      categories: [],
      selectedCategories: [],
      cascaderProps: {
        value: 'name',
        label: 'displayName',
        children: 'list',
        multiple: true,
        checkStrictly: true,
      },

      chartOptions: {},
      historyData: [],
      products: [],
      limit: 50, // 每页显示数量
      offset: 1, // 当前页码
      total: 0, // 总数据条数
      isHighlighted: false,
      loading: false, // 是否加载中
      // 排序规则
      sortord: 'salesCount_desc',
      // 五点描述
      fivePointList: [],
      description: '',
      // 搜索初始化
      searchForm: {
        asin: "",
        country_site: 'us',
        brandName: "",
        sellerName: "",
        sellerId: "",
        parentAsin: ""
      },
    };
  },
  mounted() {
    this.fetchProducts();
    this.fetchCategories();
    this.updateTitle();
  },
  methods: {
    updateWeight(value) {
      this.editForm.weight = value / 453.592;  // 将克转换为磅
    },
    handleDeleteFavorite(product) {
      this.$confirm('确定要删除选中的收藏吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 调用删除收藏接口
        this.$http.post('/v1/RemoveProductFavorites', {
          country_site: 'us', // 替换为实际的国家站点
          product_ids: [product.id],
          userid: Cookies.get("user_id"), // 替换为实际的用户 ID
          tenant_id: Cookies.get("tenant_id") // 替换为实际的租户 ID
        }).then(response => {
          if (response.data.msg === "批量取消收藏成功") {
            this.$message.success("删除收藏成功");
            // 更新产品列表
            this.fetchProducts();
          } else {
            this.$message.error("删除收藏失败");
          }
        }).catch(error => {
          console.error(error);
          this.$message.error("删除收藏失败: " + error);
        });
      }).catch(() => {
        // 用户取消操作
      });
    },
    calculateProfit(product) {
      // 简单的利润计算，需要根据实际情况调整
      // ((售价-人民币 / 汇率 * 平台佣金% / 100) + (采购成本-人民币 / 汇率) + (售价-人民币 / 汇率 * 其他费用% / 100)) * 汇率（转为人民币）
      const cost = ((product.custom_price / product.exchange_rate * product.platform_commission / 100) + (product.purchase_cost / product.exchange_rate) + (product.custom_price / product.exchange_rate * product.other_costs / 100)) * product.exchange_rate;
      return product.custom_price - cost - product.shipping_cost;
    },
    calculateProfitRate(product) {
      const profit = this.calculateProfit(product);
      return (profit / product.custom_price) * 100;
    },
    handleEdit(product) {
      this.editForm.favorite_id = product.favorite_id
      this.editForm = {
        ...this.editForm,
        ...product,
        exchangeRate: product.exchangeRate || this.editForm.exchangeRate,
        commission: product.commission || this.editForm.commission,
        otherCost: product.otherCost || this.editForm.otherCost,
        purchaseCost: product.purchaseCost || this.calculateDefaultPurchaseCost(product.price),
      }
      // 设置编辑模式
      this.editMode = product.shippingType === 1 ? 'FBA' : 'FBM';
      this.editDialogVisible = true;
      this.$nextTick(() => {
        if (this.editMode === 'FBA') {
          this.calculateFBAProfit();
        } else {
          this.calculateFBMProfit();
        }
      });
    },
    calculateDefaultPurchaseCost(price) {
      return price * 0.2; // 默认采购成本为售价的 20%
    },
    calculateFBAProfit() {
      const {
        custom_price,           // 售价(人民币)
        purchase_cost,    // 采购成本(人民币)
        shipping_cost,    // 头程运费(人民币)
        // fba_fee,          // FBA费用(人民币)
        platform_commission,      // 平台佣金(百分比)
        other_costs,       // 其他成本(百分比)
        exchange_rate,    // 汇率(人民币/美元)
        estimated_storage_time,     // 预估仓储时间(月)
        custom_weight,   // 重量(克)
      } = this.editForm;

      // 计算1-9月的净利润和净利润率
      const storageFeePerMonth1to9 = 0.18;  // 1-9月每月每立方英尺的仓储费用(美元)
      const storageFee1to9 = (storageFeePerMonth1to9 * custom_weight / 1000) * estimated_storage_time;  // 计算1-9月的仓储费用(美元)
      const netProfit1to9 = (
          custom_price / exchange_rate            // 售价-人民币转换美元
          // - fba_fee                              // 减去FBA费用
          - (custom_price  / exchange_rate * platform_commission / 100)       // 减去平台佣金
          - (purchase_cost / exchange_rate)    // 减去采购成本(转换为美元)
          - (custom_price  / exchange_rate  * other_costs / 100)        // 减去其他成本
      ) * exchange_rate                       // 将1-9月净利润转换为人民币
          - shipping_cost                    // 减去头程运费;
          - (storageFee1to9 * exchange_rate)  // 减去1-9月仓储费用(转换为人民币)

      this.editForm.netProfit1to9 = netProfit1to9.toFixed(2);  // 1-9月净利润(人民币),保留两位小数
      this.editForm.netProfitRate1to9 = ((netProfit1to9 / custom_price) * 100).toFixed(2);  // 1-9月净利润率(百分比),保留两位小数

      // 计算10-12月的净利润和净利润率
      const storageFeePerMonth10to12 = 0.46;  // 10-12月每月每立方英尺的仓储费用(美元)
      const storageFee10to12 = (storageFeePerMonth10to12 * custom_weight / 1000) * estimated_storage_time;  // 计算10-12月的仓储费用(美元)
      const netProfit10to12 = (
          custom_price / exchange_rate            // 售价-人民币转换美元
          // - fba_fee                                // 减去FBA费用
          - (custom_price  / exchange_rate * platform_commission / 100)         // 减去平台佣金
          - (purchase_cost / exchange_rate)      // 减去采购成本(转换为美元)
          - (custom_price  / exchange_rate * other_costs / 100)          // 减去其他成本
      ) * exchange_rate                         // 将10-12月净利润转换为人民币
          - shipping_cost                      // 减去头程运费;
          - (storageFee10to12 * exchange_rate)  // 减去10-12月仓储费用(转换为人民币)

      this.editForm.netProfit10to12 = netProfit10to12.toFixed(2);  // 10-12月净利润(人民币),保留两位小数
      this.editForm.netProfitRate10to12 = ((netProfit10to12 / custom_price) * 100).toFixed(2);  // 10-12月净利润率(百分比),保留两位小数
    },
    calculateFBMProfit() {
      const { custom_price, purchase_cost, fbm_shipping_fee, platform_commission, other_costs, exchange_rate } = this.editForm;
      const netProfit = (
          custom_price / exchange_rate            // 售价-人民币转换美元
          - (custom_price  / exchange_rate * platform_commission / 100)
          - (custom_price  / exchange_rate * other_costs / 100)
      ) * exchange_rate
        - 5 // 固定成本(人民币)
        - purchase_cost                // 采购成本(人民币)
        - fbm_shipping_fee             // FBM运费(人民币)

      this.editForm.netProfit = netProfit.toFixed(2);
      this.editForm.netProfitRate = ((netProfit / custom_price) * 100).toFixed(2);
    },
    submitEdit() {
      // 在这里处理编辑提交逻辑
      console.log('Submitting edit:', this.editForm);
      const params = {
        tenant_id: Cookies.get("tenant_id"),
        userid: Cookies.get("user_id"),
        country_site: this.editForm.country_site,
        favorite_id: this.editForm.favorite_id,
        exchange_rate: this.editForm.exchange_rate,
        custom_price: this.editForm.custom_price,
        purchase_cost: this.editForm.purchase_cost,
        shipping_cost: this.editForm.shipping_cost,
        fba_fee: this.editForm.fba_fee,
        fbm_shipping_fee: this.editForm.fbm_shipping_fee,
        custom_weight: this.editForm.custom_weight,
        estimated_storage_time: this.editForm.estimated_storage_time,
        platform_commission: this.editForm.platform_commission,
        other_costs: this.editForm.other_costs,
      };
      this.$http.post('/v1/UpdateUserFavoriteProduct', params).then(data => {
        if (data.data["msg"] === "修改成功"){
          this.$message.success("修改成功");
          this.fetchProducts()
        }else {
          this.$message.error(data.data['msg']);
        }
      })
      // 发送更新请求到后端
      this.editDialogVisible = false;
    },
    // 处理全选按钮变化
    handleSelectAllChange(val) {
      this.products.forEach(product => {
        product.checked = val;
      });
      this.handleSelectChange();
    },
    // 处理勾选框变化
    handleSelectChange() {
      this.selectedProducts = this.products.filter(product => product.checked);
      // 更新全选状态
      this.selectAll = this.selectedProducts.length === this.products.length;
    },
    // 处理列表视图删除收藏
    handleDeleteFavorites() {
      this.$confirm('确定要删除选中的收藏吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const productIds = this.selectedProducts.map(product => product.id);
        // 调用删除收藏接口
        this.$http.post('/v1/RemoveProductFavorites', {
          country_site: 'us', // 替换为实际的国家站点
          product_ids: productIds,
          userid: Cookies.get("user_id"), // 替换为实际的用户 ID
          tenant_id: Cookies.get("tenant_id") // 替换为实际的租户 ID
        }).then(response => {
          if (response.data.msg === "批量取消收藏成功") {
            this.$message.success("删除收藏成功");
            // 更新产品列表
            this.products = this.products.filter(product => !productIds.includes(product.id));
            this.selectedProducts = [];
          } else {
            this.$message.error("删除收藏失败");
          }
        }).catch(error => {
          console.error(error);
          this.$message.error("删除收藏失败: " + error);
        });
      }).catch(() => {
        // 用户取消操作
      });
    },
    goBack() {
      window.location.href = 'https://www.zhiyoukuajing.com/';
    },
    updateTitle() {
      document.title = '亚马逊卖家联盟-大数据选品'
    },
    // 列表数据导出
    exportData() {
      this.exportLoading = true;

      const params = {
        download_img: this.downloadImg,
        page: this.offset,
        per_page: this.limit,
        "asin": this.searchForm.asin,
        "userid": Cookies.get("user_id"),
        "tenant_id": Cookies.get("tenant_id"),
        "country_site": this.searchForm.country_site,
        "brand_name": this.searchForm.brandName,
        "seller_name": this.searchForm.sellerName
      };

      this.$http.post('/v1/GetAllFavoritesExportWork', params, { responseType: 'blob' })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '大数据选品收藏数据导出.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            console.error('导出失败', error);
          })
          .finally(() => {
            this.exportLoading = false;
            this.showExportPopover = false;
          });
    },
    async fetchCategories() {
      try {
        const response = await this.$http.get('/v1/Category');
        const categories = response.data.data.result;
        this.categories = this.formatCategories(categories);
      } catch (error) {
        console.error('获取类目失败:', error);
      }
    },
    formatCategories(categories) {
      return categories.map(category => ({
        ...category,
        displayName: `${category.name} ${category.aliasCn}`,
        list: this.formatCategories(category.list),
      }));
    },
    // asin复制
    async copyAsin(asin) {
      try {
        await navigator.clipboard.writeText(asin);
        this.$message.success('复制成功');
      } catch (err) {
        this.$message.error('复制失败');
      }
    },
    // 五点描述拆分
    fetchDescription(product) {
      const countrySite = this.searchForm.country_site;
      this.$http.post('/v1/GetDescription', {
        country_site: countrySite,
        product_id: product.id
      })
          .then(response => {
            if (response.data.state) {
              this.fivePointList = response.data.data.five_point.split(';;;;');
              this.description = response.data.data.description;
            } else {
              console.error('Failed to fetch description:', response.data.msg);
            }
          })
          .catch(error => {
            console.error('Error fetching description:', error);
          });
    },
    // 品牌商标查询
    look_brand(product) {
      this.brandInfo = ''
      const countrySite = this.searchForm.country_site;
      this.$http.post('/v1/GetLookBrand', {
        country_site: countrySite,
        brand_name: product.brandName
      })
          .then(response => {
            if (response.data.state) {
              this.brandInfo = response.data.data;
            } else {
              console.error('品牌商标查询失败:', response.data.msg);
            }
          })
          .catch(error => {
            console.error('品牌商标查询失败:', error);
          });
    },
    // 处理分页大小变更
    handleSizeChange(newSize) {
      this.limit = newSize;
      this.offset = 1; // 重置当前页码为1
      this.fetchProducts(); // 刷新列表
      this.$nextTick(() => {
        const cardId = 'card-' + this.products[0].id;
        const cardElement = document.getElementById(cardId);
        cardElement.scrollIntoView({ behavior: 'smooth' });
      });
    },

    // 处理当前页码变更
    handleCurrentChange(newPage) {
      this.offset = newPage;
      this.fetchProducts(); // 刷新列表
      this.$nextTick(() => {
        const cardId = 'card-' + this.products[0].id;
        const cardElement = document.getElementById(cardId);
        cardElement.scrollIntoView({ behavior: 'smooth' });
      });
    },
    async fetchProducts() {
      try {
        // 加载中
        this.loading = true;
        const apiUrl = `/v1/GetUserFavoriteProducts`;
        const postData = {
          page: this.offset,
          per_page: this.limit,
          "asin": this.searchForm.asin,
          "userid": Cookies.get("user_id"),
          "tenant_id": Cookies.get("tenant_id"),
          "country_site": this.searchForm.country_site,
          "brand_name": this.searchForm.brandName,
          "seller_name": this.searchForm.sellerName,
          "sortord": this.sortord,
          "exchange_rate": this.editForm.exchange_rate,
          "custom_price": this.editForm.custom_price,
          "purchase_cost": this.editForm.purchase_cost,
          "shipping_cost": this.editForm.shipping_cost,
          "fba_fee": this.editForm.fba_fee,
          "custom_weight": this.editForm.custom_weight,
          "estimated_storage_time": this.editForm.estimated_storage_time,
          "platform_commission": this.editForm.platform_commission,
          "other_costs": this.editForm.other_costs,
          "fbm_shipping_fee": this.editForm.fbm_shipping_fee,
        }
        const response = await this.$http.post(apiUrl, postData);
        const { data } = response;
        this.products = data.data;
        this.total = response.data.count;

      } catch (error) {
        this.$message.warning('未查到相应数据');
      } finally {
        // 结束加载状态
        this.loading = false;
      }
    },
    // 标题高亮
    highlightTitle(product) {
      product.isHighlighted = true;
      this.$nextTick(() => {
        const titleElement = this.$refs.titleRef.find(el => el.dataset.id === product.id);
        product.showTitleTooltip = titleElement.offsetWidth < titleElement.scrollWidth;
      });
    },
    unhighlightTitle(product) {
      product.isHighlighted = false;
    },
    goToAsinUrl(url) {
      window.open(url, '_blank');
    }
  },
  // vue计算属性
  filters: {
    // 千位符计算
    formatNumber(value) {
      if (value === null || value === undefined) {
        return ''; // or return a default value or handle the case when value is null
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  },
  watch: {
    editMode() {
      // 当编辑模式改变时，重新计算净利润
      if (this.editMode === 'FBA') {
        this.calculateFBAProfit();
      } else {
        this.calculateFBMProfit();
      }
    },
    editForm: {
      handler() {
        // 当编辑表单中的任何字段改变时，重新计算净利润
        if (this.editMode === 'FBA') {
          this.calculateFBAProfit();
        } else {
          this.calculateFBMProfit();
        }
      },
      deep: true
    }
  },
};
</script>

<style scoped>
.product-card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.el-card {
  flex: 1 0 calc(16.5% - 16px);
  max-width: calc(16.5% - 16px);
  margin-bottom: 5px;
}

@media screen and (max-width: 1200px) {
  .el-card {
    flex: 1 0 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
}

@media screen and (max-width: 992px) {
  .el-card {
    flex: 1 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
}

@media screen and (max-width: 768px) {
  .el-card {
    flex: 1 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media screen and (max-width: 576px) {
  .el-card {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.large-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.title {
  position: relative;
}

.title-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1;
}

.info-box {
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 10px;
}
.el-card {
  font-size: 0.8rem; /* reduce font size to 0.8rem */
}

.el-card .title {
  font-size: 1rem; /* reduce font size to 1rem */
}

.el-card .info-box {
  font-size: 0.8rem; /* reduce font size to 0.9rem */
}

.thumbnail {
  position: relative;
}

.large-image {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


/* 标题高亮+链接跳转 */
.title {
  position: relative;
  cursor: pointer;
}

.title-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-text.highlight {
  background-color: #e6f7ff;
  color: #1890ff;
}

/* 搜索+筛选 */

.search-module {
  margin-bottom: 20px;
}

.search-module .el-input {
  width: 200px;
  margin-right: 10px;
}

.filter-module {
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-label {
  margin-right: 10px;
}

.filter-item .el-select {
  width: 120px;
  margin-right: 10px;
}

.filter-item .el-input {
  width: 100px;
  margin-right: 10px;
}

.el-icon-question {
  margin-left: 5px;
  cursor: pointer;
}

/* 搜索+筛选样式 */
.search-module {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.search-container .el-input {
  margin: 10px;
  width: 200px;
}

.search-container .el-button {
  margin: 10px;
}

.filter-container {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  border: none;
}

.filter-module {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-item {
  display: flex;
  align-items: center;
  margin: 10px;
}

.filter-label {
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.filter-item .el-select {
  width: 150px;
  margin-right: 10px;
}

.filter-item .el-input {
  width: 150px;
  margin-right: 10px;
}

.filter-item .el-tooltip {
  margin-left: 10px;
}

.filter-item .el-date-picker {
  width: 150px;
  margin-right: 10px;
}

.filter_text{
  color: #2396fa;
}


.filter-module {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-item {
  margin-bottom: 20px;
}

.filter-btn {
  margin-top: 20px;
  text-align: center;
}

.filter-btn .el-button {
  width: 100px;
  margin: 0 auto;
}


/* 确认筛选按钮样式 */
.filter-module {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-item {
  margin-bottom: 20px;
}

.filter-btn {
  margin-top: 20px;
  text-align: center;
}

.filter-btn .el-button {
  width: 100px;
  margin: 0 auto;
}


/* 搜索栏样式 */
.search-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.el-input, .el-select {
  margin-right: 10px;
}

.el-input:last-child, .el-select:last-child {
  margin-right: 0;
}

/* 分页组件靠右显示 */
.pagination-right {
  float: right;
}

/* 标题栏高亮蓝色显示 */
.title-text:hover {
  color: #337ab7; /* blue color */
  text-decoration: underline; /* add an underline effect */
  cursor: pointer; /* change the cursor to a pointer */
}

/* 卡片文字易超出部分限制行数 */
.card_title-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 星级和评价显示在一行 */
.rating {
  display: flex;
  align-items: center;
}

.rate {
  display: inline-block;
}

.reviews {
  display: inline-block;
  margin-left: 10px;
  font-size: 19px;
}

/* 通用设置文字靠最右显示 */
.a_row {
  display: inline-block;
  float: right;
}


.brand-info-wrapper {
  padding: 10px;
}

.brand-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.brand-info-label {
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.brand-info-value {
  display: flex;
  align-items: center;
}

.nice-class-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.no-brand-info {
  text-align: center;
  padding: 20px;
  color: #999;
}

.brand-info-button {
  margin-top: 10px;
}


/* 品牌商标信息 */
.brand-info-wrapper {
  padding: 10px;
}

.brand-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.brand-info-label {
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.brand-info-value {
  display: flex;
  align-items: center;
}

.nice-class-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.no-brand-info {
  text-align: center;
  padding: 20px;
  color: #999;
}

.brand-info-button {
  margin-top: 10px;
}

.row-group {
  margin-top: 0; /* 调整 el-row 的上外边距 */
  margin-bottom: 0; /* 调整 el-row 的下外边距 */
}
</style>
