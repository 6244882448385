import Vue from 'vue'
import ElementUI from 'element-ui';
// 引入element部分组件
import {Input,Form,FormItem,Button,Container,Header,Main,aside,Footer} from 'element-ui'
Vue.use(Button)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Form)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(aside)
Vue.use(Footer)