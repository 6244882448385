<template>
  <div class="login-container">
    <div class="login-box">
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="80px"
          class="login-form"
      >
        <h2 class="login-title">智优跨境-大数据选品中心</h2>
        <div v-if="activeTab === 'login'">
          <el-form-item label="账号" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item class="compact-form-item" style="margin-top: -15px">
            <div class="button-group">
              <el-button type="text" @click="activeTab = 'register'" style="margin-right: 50px">注册</el-button>
              <el-button type="text" @click="activeTab = 'forgot'">忘记密码</el-button>
            </div>
            <el-button type="primary" @click="handleLogin" class="login-button">登录</el-button>
          </el-form-item>
        </div>

        <div v-else-if="activeTab === 'register'">
          <el-form-item label="账号" prop="username">
            <el-input v-model="form.username"></el-input>
            <div class="error-message">{{ errors.username }}</div>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="form.password"></el-input>
            <div class="error-message">{{ errors.password }}</div>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input
                type="password"
                v-model="form.confirmPassword"
            ></el-input>
            <div class="error-message">{{ errors.confirmPassword }}</div>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email"></el-input>
            <div class="error-message">{{ errors.email }}</div>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone"></el-input>
            <div class="error-message">{{ errors.phone }}</div>
          </el-form-item>
          <el-form-item label="公司名称" prop="real_name">
            <el-input v-model="form.real_name"></el-input>
            <div class="error-message">{{ errors.real_name }}</div>
          </el-form-item>
          <div class="button-group">
            <el-form-item>
              <el-button type="primary" @click="handleRegister">注册</el-button>
              <el-button type="text" @click="activeTab = 'login'"
              >返回登录</el-button
              >
            </el-form-item>
          </div>

        </div>

        <div v-else-if="activeTab === 'forgot'">
          <el-form-item label="账号" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input type="password" v-model="form.oldPassword"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="form.newPassword"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="button-group">
              <el-button type="primary" @click="handleForgotPassword">重置密码</el-button>
              <el-button type="text" @click="activeTab = 'login'">返回登录</el-button>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!/^(?=.*\d).{6,18}$/.test(value)) {
        callback(
            new Error("密码必须包含8-18位英文和数字")
        );
      } else {
        callback();
      }
    };
    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      activeTab: "login",
      form: {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        phone: "",
        real_name: "",
        oldPassword: "",
        newPassword: "",
      },
      errors: {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        phone: "",
        real_name: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validateConfirmPassword, trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { type: "email", message: "请输入有效的邮箱地址", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur",
          },
        ],
        real_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http
              .post("/v1/login", {
                username: this.form.username,
                password: this.form.password,
              })
              .then((response) => {
                const data = response.data;
                if (!data.state) {
                  this.$message.error(data.msg);
                } else {
                  // 存储token
                  window.sessionStorage.setItem(
                      "token",
                      "Bearer " + data.token
                  );
                  // 存储用户角色和权限信息
                  window.sessionStorage.setItem(
                      "userRoles",
                      JSON.stringify(data.data.roles)
                  );
                  window.sessionStorage.setItem(
                      "userPermissions",
                      JSON.stringify(data.data.permissions)
                  );

                  this.$router.push("/home");
                  Cookies.set("user_id", data.data.user_id);
                  Cookies.set("username", data.data.user_name);
                  Cookies.set("real_name", data.data.real_name);
                  Cookies.set("phone", data.data.phone);
                  Cookies.set("email", data.data.email);
                  Cookies.set("tenant_id", data.data.tenant_id);
                  Cookies.set("created_time", data.data.created_time);
                  Cookies.set(
                      "community_user_name",
                      data.data.community_user_name
                  );
                  Cookies.set(
                      "community_user_phone",
                      data.data.community_user_phone
                  );
                  this.$message.success("登录成功");
                }
              })
              .catch((error) => {
                console.error("登录错误:", error);
                this.$message.error("登录失败，请检查网络或联系管理员");
              });
        }
      });
    },
    handleRegister() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http
              .post("/v1/reg", {
                username: this.form.username,
                password: this.form.password,
                email: this.form.email,
                phone: this.form.phone,
                real_name: this.form.real_name,
                tenant_type: true, // 根据实际情况修改
              })
              .then((response) => {
                const data = response.data;
                if (!data.state) {
                  this.$message.error(data.msg);
                } else {
                  this.$message.success("注册成功");
                  // 注册成功后跳转到登录页面
                  this.activeTab = "login";
                }
              })
              .catch((error) => {
                console.error("注册错误:", error);
                this.$message.error("注册失败，请检查网络或联系管理员");
              });
        }
      });
    },
    handleForgotPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http
              .post("/v1/UserPasswordVerification", {
                username: this.form.username,
                password: this.form.oldPassword,
                new_password: this.form.newPassword,
              })
              .then((response) => {
                const data = response.data;
                if (!data.state) {
                  console.log(data.msg)
                  this.$message.error(data.msg);
                } else {
                  this.$message.success("密码重置成功");
                  // 重置密码成功后跳转到登录页面
                  this.activeTab = "login";
                }
              })
              .catch((error) => {
                console.error("密码重置错误:", error);
                this.$message.error("密码重置失败，请检查网络或联系管理员");
              });
        }
      });
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: coral;
}

.login-box {
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  overflow: hidden;
}


.login-form {
  width: 100%;
  padding: 80px;
  margin-right: 40px;
}

.login-title {
  color: #303133;
  margin-left: 50px;
  margin-bottom: 30px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* 按钮组样式 */
.button-group {
  display: flex;
  justify-content: center; /* 水平居中 */
}

/* 登录按钮样式 */
.login-button {
  width: 100%;
}
</style>