<template>
  <div>
    <el-card>
      <el-descriptions class="margin-top" title="简介" :column="2" border>
<!--        <el-descriptions-item>-->
<!--          <template slot="label">-->
<!--            <i class="el-icon-picture-outline"></i>-->
<!--            头像-->
<!--          </template>-->
<!--          <img class="img" :src="avatar" alt="" />-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            账户名
          </template>
          {{ account }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-custom"></i>
            昵称
          </template>
          {{ nickname }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-message"></i>
            邮箱Email
          </template>
          {{ email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号码
          </template>
          {{ mobilePhoneNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            注册日期
          </template>
          {{ createDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            修改密码
          </template>
          <div>
            <el-button type="text" @click="dialogVisible = true">操作</el-button>

            <el-dialog title="修改密码" :visible.sync="dialogVisible" width="400px">
              <el-form :model="passwordForm" :rules="rules" ref="passwordForm" label-width="100px">
                <el-form-item label="旧密码" prop="oldPassword">
                  <el-input v-model="passwordForm.oldPassword" type="password" placeholder="请输入旧密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                  <el-input v-model="passwordForm.newPassword" :type="passwordVisible ? 'text' : 'password'" placeholder="请输入新密码">
                    <i slot="suffix" class="el-icon-view" @click="passwordVisible = !passwordVisible"></i>
                  </el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleSubmit">确认</el-button>
              </span>
            </el-dialog>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            智优跨境账号
          </template>
          <div>
            <el-button type="text" @click="openDialog" v-if="!isBound">绑定</el-button>
            <el-button type="text" @click="unbindAccount" v-else>解除绑定</el-button>
            <span style="color: #4cafa0">         {{ BoundInfoName }}</span>
            <span v-if="!!BoundInfoName" style="color: rgba(220,174,79,0.98)">         会员等级：{{ user_right_info.userType }}  </span>
            <span v-if="user_right_info.userType !== '非会员' && !!user_right_info.userType" style="color: #d9484b">         到期时间：{{ user_right_info.endTime }}</span>
            <el-dialog title="绑定智优跨境社区论坛账号" :visible.sync="dialogVisible_binding" width="400px">
              <el-form ref="form" :model="form" :rules="rules_binding" label-width="120px">
                <el-form-item label="用户名" prop="community_user_name">
                  <el-input v-model="form.community_user_name" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="community_user_phone">
                  <el-input v-model="form.community_user_phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm">确认</el-button>
                  <el-button @click="resetForm">取消</el-button>
                </el-form-item>
              </el-form>
              <div v-if="responseMsg" class="response-msg">{{ responseMsg }}</div>
            </el-dialog>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "Info",
  data() {
    const validatePassword = (rule, value, callback) => {
      const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,18}$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!passwordRegex.test(value)) {
        callback(new Error('密码必须包含8-18位英文和数字'));
      } else {
        callback();
      }
    };
    return {
      //
      user_right_info: {
        userType: "",
        endTime: ""
      },
      // 判断是否绑定社区账号
      isBound: false,
      BoundInfoName: "",

      dialogVisible_binding: false,
      form: {
        community_user_name: '',
        community_user_phone: '',
      },
      rules_binding: {
        community_user_name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        community_user_phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
      },
      responseMsg: '',

      avatar: String,
      account: Cookies.get("username"),
      email: Cookies.get("email"),
      mobilePhoneNumber: Cookies.get("phone"),
      createDate: Cookies.get("created_time"),
      nickname: Cookies.get("real_name"),
      // 密码修改默认参数
      dialogVisible: false,
      passwordVisible: false,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPassword: [
          { required: true, validator: validatePassword, trigger: 'blur' },
        ],
      },
    };
  },
  mounted() {
    // 判断是否已绑定
    this.checkAccountBinding()
    this.IfUserRight()
  },
  methods: {
    // 社区账号绑定弹框
    openDialog() {
      this.dialogVisible_binding = true;
    },
    // 社区账号
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const requestData = {
            user_id: Cookies.get("user_id"),
            user_name: Cookies.get("username"),
            community_user_name: this.form.community_user_name,
            community_user_phone: this.form.community_user_phone,
          };

          this.$http.post('/v1/SubAccountBinding', requestData)
              .then((response) => {
                const data = response.data;
                console.log(data)
                this.responseMsg = data.msg;
                this.checkAccountBinding()
              })
              .catch((error) => {
                this.responseMsg = error;
              });
        }
      });
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.dialogVisible_binding = false;
    },
    // 获取智优跨境账号会员信息、到期时间
    IfUserRight() {
      const requestData = {
        user_id: Cookies.get("user_id"),
        user_name: Cookies.get("username"),
        community_user_name: Cookies.get("community_user_name"),
        community_user_phone: Cookies.get("community_user_phone"),
      };

      this.$http.post('/v1/IfUserRightAPI', requestData)
          .then((response) => {
            // console.log(response.data.data.userType)
            this.user_right_info.userType = response.data.data.userType;
            this.user_right_info.endTime = response.data.data.endTime;
          })
          .catch((error) => {
            this.responseMsg = error;
          });

    },
    // 密码修改
    handleSubmit() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          const { oldPassword, newPassword } = this.passwordForm;
          // 发送修改密码请求
          this.$http.post('/v1/UserPasswordVerification', {
            username: Cookies.get("username"),
            password: oldPassword,
            new_password: newPassword,
          })
              .then((response) => {
                // 处理修改密码成功的逻辑
                console.log(response);
                if (response.data.msg === "修改密码成功"){
                  this.$message.success('密码修改成功');
                }
                if (response.data.msg === "旧密码错误"){
                  this.$message.warning('旧密码错误');
                }
                this.dialogVisible = false;
              })
              .catch((error) => {
                // 处理修改密码失败的逻辑
                console.error(error);
                this.$message.error("系统异常："+ error);
              });
        }
      });
    },
    checkAccountBinding() {
      // 请求后端接口判断账号是否已绑定
      this.$http.post('/v1/CheckSubAccountBinding', {
        user_id: Cookies.get("user_id"),
        user_name: Cookies.get("username")
      }).then(response => {
        if (response.data.code === 200) {
          this.isBound = response.data.data.is_bound
          if (this.isBound){
            this.BoundInfoName = response.data.data.community_user_name
          }
        } else {
          console.error(response.data.msg)
        }
      }).catch(error => {
        console.error(error)
      })
    },
    unbindAccount() {
      // 解除绑定确认弹框
      this.$confirm('确定解除绑定智优跨境社区论坛账号吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 请求后端接口解除绑定
        this.$http.post('/v1/SubAccountUnbind', {
          user_id: Cookies.get("user_id"),
          user_name: Cookies.get("username")
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success(response.data.msg)
            this.isBound = false
            this.BoundInfoName = ""
            this.user_right_info.userType = ""
            this.user_right_info.endTime = ""
          } else {
            this.$message.error(response.data.msg)
          }
        }).catch(error => {
          console.error(error)
        })
      }).catch(() => {
        // 取消解绑
      })
    }
  },
};
</script>

<style scoped>
.img {
  width: 80px;
  height: 80px;
}

.response-msg {
  margin-top: 10px;
  color: red;
}
</style>
