<template>
  <div class="container">
    <h1 class="title">亚马逊ASIN找店铺</h1>
    <div class="site-selection">
      <el-radio-group v-model="selectedSite">
        <el-radio-button label="1">美国</el-radio-button>
        <el-radio-button label="10">加拿大</el-radio-button>
        <el-radio-button label="11">墨西哥</el-radio-button>
        <el-radio-button label="7">英国</el-radio-button>
        <el-radio-button label="3">德国</el-radio-button>
        <el-radio-button label="4">法国</el-radio-button>
        <el-radio-button label="5">意大利</el-radio-button>
        <el-radio-button label="6">西班牙</el-radio-button>
        <el-radio-button label="2">日本</el-radio-button>
        <el-radio-button label="8">澳大利亚</el-radio-button>
        <el-radio-button label="9">巴西</el-radio-button>
      </el-radio-group>
    </div>
    <div class="search-input">
      <el-input
          placeholder="请输入ASIN"
          v-model="searchText"
          style="margin-right: 30px"
      ></el-input>
      <el-button type="primary" @click="search" style="width: 100px" :loading="loading" :disabled="loading">搜索</el-button>
      <el-button type="success" @click="exportData" style="width: 100px; margin-left: 10px" :loading="exportLoading" :disabled="loading">
        <i class="el-icon-download"></i> 导出
      </el-button>
    </div>
    <span v-if="!!prostituting_count">共查询到跟卖总数： {{ prostituting_count }}</span>
    <div class="search-result">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" label="序号" width="70"></el-table-column>
        <el-table-column prop="country_site" label="站点" width="70"></el-table-column>
        <el-table-column prop="asin" label="ASIN" width="150" ></el-table-column>
        <el-table-column prop="seller_name" label="卖家名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="seller_id" label="卖家ID" show-overflow-tooltip></el-table-column>
        <el-table-column prop="seller_link" label="卖家链接" show-overflow-tooltip>
          <template slot-scope="scope">
            <a :href="scope.row.seller_link" target="_blank">{{ scope.row.seller_link }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" width="120" sortable></el-table-column>
        <el-table-column prop="review_count" label="评论统计" width="400"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      exportLoading: false, // 导出按钮 loading 状态
      selectedSite: '1',
      searchText: '',
      tableData: [],
      loading: false,
      prostituting_count: ''
    };
  },
  methods: {
    async exportData() {
      if (this.searchText.trim() === '') {
        this.$message.warning('请填写ASIN');
        return;
      }
      this.exportLoading = true;
      const asinList = this.searchText
          .split('\n')
          .filter(line => line.trim() !== '');

      try {
        const response = await this.$http.post('/v1/ExportAsinTheStoreList', {
          tenant_id: Cookies.get("tenant_id"),
          user_id: Cookies.get("user_id"),
          user_name: Cookies.get("username"),
          community_user_name: Cookies.get("community_user_name"),
          community_user_phone: Cookies.get("community_user_phone"),
          selected_site: this.selectedSite,
          asin_list: asinList
        }, { responseType: 'blob' }); // 指定响应类型为 blob

        // 处理下载
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ExportAsinTheStoreList.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('导出异常:', error);
        this.$message.error('导出异常，请稍后重试');
      } finally {
        this.exportLoading = false;
      }
    },
    async search() {
      if (this.searchText.trim() === '') {
        this.$message.warning('请填写ASIN');
        return;
      }
      const asinList = this.searchText
          .split('\n')
          .filter(line => line.trim() !== ''); // 过滤掉空行

      try {
        this.loading = true; // 发送请求前将 loading 设置为 true
        const response = await this.$http.post('/v1/GetAsinTheStoreList', {tenant_id: Cookies.get("tenant_id"),user_id: Cookies.get("user_id"),
          user_name: Cookies.get("username"), community_user_name: Cookies.get("community_user_name"),
          community_user_phone: Cookies.get("community_user_phone"), selected_site: this.selectedSite, asin_list: asinList });
        if (response.data.code === 200) {
          const countrySite = response.data.data.find(data => data.country_site)?.country_site || '';
          this.prostituting_count = response.data.data.find(data => data.prostituting_count)?.prostituting_count || '';
          this.tableData = response.data.data.flatMap(item => {
            if (Array.isArray(item)) {
              return item.map(storeInfo => ({
                country_site: countrySite,
                asin: storeInfo.asin,
                seller_name: storeInfo.seller_name,
                seller_id: storeInfo.seller_id,
                seller_link: storeInfo.seller_link,
                price: storeInfo.price,
                review_count: storeInfo.review_count
              }));
            }
            return [];
          });
        } else {
          console.error('请求失败:', response.data.msg);
          this.$message.warning(response.data.msg);
        }
      } catch (error) {
        console.error('请求异常:', error);
        this.$message.error('请求异常，请稍后重试');
      } finally {
        this.loading = false; // 请求完成后将 loading 设置回 false
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.site-selection {
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  margin-bottom: 20px;
}

.search-input .el-input {
  flex: 1;
  margin-right: 10px;
}

.search-input .el-button {
  width: 100px;
}
</style>
