<template>
  <el-container class="home_heg">
    <el-header>
      <div class="logo_header">
        <!--        <img id="logo_img" src="../assets/logo2.png" alt="">-->
        <router-link to="/welcom" class="router-link">
          <span>大数据选品中心</span>
        </router-link>
      </div>
      <el-button class="home_cler" type="info" @click="logut">欢迎 {{user_infos.user_name}} | 退出</el-button>
    </el-header>
    <!--    侧边栏滑动条-->
    <el-container>
      <el-aside :width="isCollapse ? '64px' : '170px' ">
        <!--        侧边栏展开缩放-->
        <div class="fold">
          <el-button type="text" icon="el-icon-d-arrow-right" v-if="isCollapse" @click="isCollapse = false"></el-button>
          <el-button type="text" icon="el-icon-d-arrow-left" v-else @click="isCollapse = true"></el-button>
        </div>
        <div class="line"></div>
        <el-menu class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" :collapse="isCollapse" unique-opened collapse-transition router>
          <!-- 一级菜单 -->
          <el-menu-item v-if="item.name === '首页'" v-for="item in menuList.data" :index="item.url" @click="goToHomePage">
            <i class="el-icon-s-home"></i>
            <span slot="title" v-if="!isCollapse">社区首页</span>
          </el-menu-item>
          <!-- 只有当一级菜单下存在子菜单时，才显示一级菜单 -->
          <el-submenu v-if="item.name !== '首页' && item.children && item.children.length > 0" :index="item.id+''" v-for="item in menuList.data" :key="item.id">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class=item.icon></i>
              <!-- 菜单名称 -->
              <span>{{item.name}}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item :index="subItem.url" v-for="subItem in item.children" :key="subItem.id">
              <template slot="title">
                <i class=""></i>
                <span slot="title">{{subItem.name}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-scrollbar style="height:100%">
        <el-main class="main">
          <router-view></router-view>
        </el-main>
      </el-scrollbar>
    </el-container>
  </el-container>

</template>
<script>

import Cookies from "js-cookie";

export default {
  data(){
    return{
      tenant_id: Cookies.get("tenant_id"),
      user_infos:{
        user_name: null,
      },
      //左侧菜单数据
      menuList:[],
      isCollapse: false,
    }
  },
  created() {
    this.GetHomeList()
  },
  methods:{
    GetHomeList(){
      this.user_infos.user_name = Cookies.get("username")
      this.$http.post('/v1/AdminMenu', this.user_infos).then(response => {
        this.menuList = response.data;
        console.log(this.menuList)
        this.$router.push('/datas');
        // console.logs(response.data.data[0]["username"])
      }).catch(error => {
        console.log(error);
      })
    },
    logut(){
      window.sessionStorage.clear();
      this.$router.push('/login')
    },
    goToHomePage() {
      window.open('https://www.zhiyoukuajing.com/', '_blank');
    }
  }
}
</script>

<style scoped>
.el-header{
  background-color: #409EFF;
}
.el-aside{
  background-color: #545c64;

}
.el-main{
  background-color: #FFFFFF;
}
.el-menu{
  border-right-width: 0;

}
.home_heg{
  height: 100%;
}
.home_cler{
  background-color: #4c5669;
  float: right;
  margin-top:-18px;
}
.logo_header{
  height: 10px;
  width: 180px;
  font-size: 20px;
  text-align: left;
  margin-top:18px;
  margin-left: 30px;
  color: #FFFFFF;
}
/*侧边导航折叠按钮*/
.fold{
  position: relative;
  left: 20%;
  margin-top: 10px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 170px;
  min-height: 400px;
}
.router-link{
  text-decoration: none;
  color: white;
}

</style>
