<template>
  <div id="app">
    <!-- 组件渲染出口 -->
    <router-view></router-view>
  </div>
</template>

<style >
body{
  font-family: "微软雅黑";  /*  设置字体 */
  margin: 0px auto /* 去除上下的边距*/
}
</style>

<script>
export default  {
  name:  'App',
  created() {
    //当浏览器刷新时，强制跳转到首页
    if (this.$router.path !== "/home")
      this.$router.replace("home")
  }
}
</script>
