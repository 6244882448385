<template>
  <div  v-loading="loading">
    <!-- 搜索模块 -->
    <div class="search-module content-container">
      <div class="search-container">
        <el-select v-model="searchForm.country_site" placeholder="国家站点">
          <el-option label="美国" value="us"></el-option>
          <el-option label="德国" value="de"></el-option>
          <el-option label="法国" value="fr"></el-option>
          <el-option label="英国" value="uk"></el-option>
          <el-option label="西班牙" value="es"></el-option>
          <el-option label="意大利" value="it"></el-option>
          <el-option label="加拿大" value="ca"></el-option>
          <el-option label="日本" value="jp"></el-option>
        </el-select>
        <el-input v-model="searchForm.asin" placeholder="ASIN" clearable></el-input>
        <el-input v-model="searchForm.brandName" placeholder="品牌名称" clearable></el-input>
        <el-input v-model="searchForm.sellerName" placeholder="卖家名称" clearable></el-input>
        <el-input v-model="searchForm.sellerId" placeholder="卖家ID" clearable></el-input>
        <el-input v-model="searchForm.parentAsin" placeholder="父ASIN" clearable></el-input>
        <el-button type="primary" @click="fetchProducts"><i class="el-icon-search"></i>搜索</el-button>
      </div>
      <!-- 筛选模块 -->
      <div class="filter-container">
        <el-collapse v-model="activeNames">
          <el-collapse-item>
            <template slot="title">
              <div class="filter_text">
                增加筛选条件<i class="header-icon el-icon-info"></i>
              </div>
            </template>
            <div class="filter-module">
              <div class="filter-item">
                <span class="filter-label">类目：</span>
                <el-cascader
                    v-model="selectedCategories"
                    :options="categories"
                    :props="cascaderProps"
                    :show-all-levels="false"
                    clearable
                    filterable
                    multiple
                    placeholder="请选择或搜索类目，支持模糊搜索，支持多选"
                    @change="handleCategoryChange"
                    style="width: 500px"
                ></el-cascader>
              </div>

              <div class="filter-item">
                <span class="filter-label">月销量：</span>
                <el-select v-model="filterForm.salesCount" @change="handleSalesCountChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="1~100" value="1-100"></el-option>
                  <el-option label="101~300" value="101-300"></el-option>
                  <el-option label="301~500" value="301-500"></el-option>
                  <el-option label="501~1000" value="501-1000"></el-option>
                  <el-option label="1001~5000" value="1001-5000"></el-option>
                  <el-option label="5001~10000" value="5001-10000"></el-option>
                  <el-option label=">10000" value="10000"></el-option>
                </el-select>
                <el-input v-model="filterForm.salesCount_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.salesCount_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="指父ASIN近30天的销量，如果选择了某月，则为该自然月的月销量
产品父体近30天总销量或某自然月的月总销量(FBA+FBM)，是基于该父体下所有跟卖销量之和
对于中小卖家，选择月销量300-1000可以较好平衡投入和产出" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">月销售额：</span>
                <el-select v-model="filterForm.sales" @change="handleSalesChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="1~100" value="1-100"></el-option>
                  <el-option label="101~300" value="101-300"></el-option>
                  <el-option label="301~500" value="301-500"></el-option>
                  <el-option label="501~1000" value="501-1000"></el-option>
                  <el-option label="1001~5000" value="1001-5000"></el-option>
                  <el-option label="5001~10000" value="5001-10000"></el-option>
                  <el-option label=">10000" value="10000"></el-option>
                </el-select>
                <el-input v-model="filterForm.sales_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.sales_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="指父ASIN近30天的销售额，如果选择了某月，则为该自然月的月销售额
该父体近30天总销售额或某个自然月总销售额(FBA+FBM)，是基于该父体下所有跟卖销售额之和" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">毛利率：</span>
                <el-select v-model="filterForm.profit" @change="handleProfitChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="10%" value="<10"></el-option>
                  <el-option label="10%~30%" value="10-30"></el-option>
                  <el-option label="31%~50%" value="31-50"></el-option>
                  <el-option label="51%~70%" value="51-70"></el-option>
                  <el-option label="71%~80%" value="71-80"></el-option>
                  <el-option label="81%~90%" value="81-90"></el-option>
                  <el-option label="91%~100%" value="91-100"></el-option>
                </el-select>
                <el-input v-model="filterForm.profit_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.profit_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="产品毛利率，系统默认毛利率 = (商品售价-亚马逊佣金-FBA运费)/商品售价*100%
如果选择了某月，则为查询月份平均毛利率" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">BRS：</span>
                <el-select v-model="filterForm.brs" @change="handleBRSChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="1-20" value="1-20"></el-option>
                  <el-option label="21-50" value="21-50"></el-option>
                  <el-option label="51~100" value="51-100"></el-option>
                  <el-option label="101~200" value="101-200"></el-option>
                  <el-option label="201~500" value="201-500"></el-option>
                  <el-option label="501~1000" value="501-1000"></el-option>
                  <el-option label=">1000" value="1000"></el-option>
                </el-select>
                <el-input v-model="filterForm.brs_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.brs_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="该ASIN在数据抓取时的BSR排名" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">评分：</span>
                <el-select v-model="filterForm.star" @change="handleStarChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="0~2" value="0-2"></el-option>
                  <el-option label="2.1~3" value="2.1-3"></el-option>
                  <el-option label="3.1~3.7" value="3.1-3.7"></el-option>
                  <el-option label="3.8~4.2" value="3.8-4.2"></el-option>
                  <el-option label="4.3~4.5" value="4.3-4.5"></el-option>
                  <el-option label="4.6~4.7" value="4.6-4.7"></el-option>
                  <el-option label="4.8~5.0" value="4.8-5"></el-option>
                </el-select>
                <el-input v-model="filterForm.star_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.star_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="亚马逊最新抓取评分值" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">Buybox价格：</span>
                <el-select v-model="filterForm.buybox" @change="handleBuyboxChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="0~10" value="0-10"></el-option>
                  <el-option label="11~25" value="11-25"></el-option>
                  <el-option label="26~50" value="26-50"></el-option>
                  <el-option label="51~100" value="51-100"></el-option>
                  <el-option label="101~200" value="101-200"></el-option>
                  <el-option label="201~500" value="201-500"></el-option>
                  <el-option label=">500" value="500"></el-option>
                </el-select>
                <el-input v-model="filterForm.buybox_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.buybox_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="产品的Buybox价格" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">上架时间：</span>
                <el-select v-model="filterForm.listingTime" @change="handleListingTimeChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="3个月内" value="3"></el-option>
                  <el-option label="半年内" value="6"></el-option>
                  <el-option label="1年内" value="12"></el-option>
                  <el-option label="2年内" value="24"></el-option>
                  <el-option label="3年内" value="36"></el-option>
                  <el-option label="4年内" value="48"></el-option>
                  <el-option label="≥4年" value="48"></el-option>
                </el-select>
                <el-date-picker
                    v-model="filterForm.listingTime_start"
                    type="date"
                    placeholder="起始日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
                <el-date-picker
                    v-model="filterForm.listingTime_end"
                    type="date"
                    placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
                <el-tooltip effect="dark" content="亚马逊抓取该产品的上架时间" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">跟卖数：</span>
                <el-select v-model="filterForm.seller_follow" @change="handlesellerFollowChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="<1" value="<1"></el-option>
                  <el-option label="1~5" value="1-5"></el-option>
                  <el-option label="6~10" value="6-10"></el-option>
                  <el-option label="31~50" value="31-50"></el-option>
                  <el-option label=">50" value="50"></el-option>
                </el-select>
                <el-input v-model="filterForm.seller_follow_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.seller_follow_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="亚马逊抓取该产品的跟卖数" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">重量：</span>
                <el-select v-model="filterForm.weight" @change="handleWeightChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="<0.01" value="<0.01"></el-option>
                  <el-option label="0.01~0.05" value="0.01-0.05"></el-option>
                  <el-option label="0.05~0.1" value="0.05-0.1"></el-option>
                  <el-option label="0.1~0.5" value="0.1-0.5"></el-option>
                  <el-option label="0.5~1" value="0.5-1"></el-option>
                  <el-option label="1~5" value="1-5"></el-option>
                  <el-option label=">5" value="5"></el-option>
                </el-select>
                <el-input v-model="filterForm.weight_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.weight_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="产品的净重，单位为“磅”，包括盎司等单位也全部转换为了“磅”" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">体积：</span>
                <el-select v-model="filterForm.volume" @change="handleVolumeChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="<5" value="<5"></el-option>
                  <el-option label="5~10" value="5-10"></el-option>
                  <el-option label="10~20" value="10-20"></el-option>
                  <el-option label="20~50" value="20-50"></el-option>
                  <el-option label="50~100" value="50-100"></el-option>
                  <el-option label="100~500" value="100-500"></el-option>
                  <el-option label=">500" value="500"></el-option>
                </el-select>
                <el-input v-model="filterForm.volume_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.volume_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="长 x 宽 x 高（英寸" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div class="filter-item">
                <span class="filter-label">变体数：</span>
                <el-select v-model="filterForm.subAsinCount" @change="handlesubAsinCountChange">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="0" value="0"></el-option>
                  <el-option label="1~5" value="1-5"></el-option>
                  <el-option label="6~10" value="6-10"></el-option>
                  <el-option label="11~20" value="11-20"></el-option>
                  <el-option label="21~30" value="21-30"></el-option>
                  <el-option label="31~50" value="31-50"></el-option>
                  <el-option label=">50" value="50"></el-option>
                </el-select>
                <el-input v-model="filterForm.subAsinCount_min" placeholder="起始值"></el-input>
                <el-input v-model="filterForm.subAsinCount_max" placeholder="结束值"></el-input>
                <el-tooltip effect="dark" content="产品的变体数量" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <div>
                <el-row>
                  <span class="filter-label">尺寸：</span>
                  <el-button size="mini" @click="handleSizeClick('')">不限</el-button>
                  <el-button size="mini" @click="handleSizeClick(1)">小号标准尺寸</el-button>
                  <el-button size="mini" @click="handleSizeClick(2)">大号标准尺寸</el-button>
                  <el-button size="mini" @click="handleSizeClick(3)">小号大件</el-button>
                  <el-button size="mini" @click="handleSizeClick(4)">中号大件</el-button>
                  <el-button size="mini" @click="handleSizeClick(5)">大号大件</el-button>
                  <el-button size="mini" @click="handleSizeClick(6)">特殊大件</el-button>
                  <el-tooltip effect="dark" content="按照亚马逊标准尺寸进行分段对于中小卖家，选择小号标准尺寸过滤，可以帮您找到FBA费用少且盈利高的产品" placement="top">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </el-row>
              </div>
              <div>
                <el-row>
                  <span class="filter-label">卖家所在地：</span>
                  <el-button size="mini" @click="handleLocationClick('')">不限</el-button>
                  <el-button size="mini" @click="handleLocationClick('CN')">中国</el-button>
                  <el-button size="mini" @click="handleLocationClick('US')">美国</el-button>
                  <el-button size="mini" @click="handleLocationClick('CA')">加拿大</el-button>
                  <el-button size="mini" @click="handleLocationClick('ES')">西班牙</el-button>
                  <el-button size="mini" @click="handleLocationClick('GB')">英国</el-button>
                  <el-button size="mini" @click="handleLocationClick('DE')">德国</el-button>
                  <el-button size="mini" @click="handleLocationClick('JP')">日本</el-button>
                  <el-tooltip effect="dark" content="产品的Buybox卖家所属地" placement="top">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </el-row>
              </div>
              <div>
                <el-row>
                  <span class="filter-label">Buybox配送：</span>
                  <el-button size="mini" @click="handleshippingTypeClick('')">不限</el-button>
                  <el-button size="mini" @click="handleshippingTypeClick(1)">Amazon自营</el-button>
                  <el-button size="mini" @click="handleshippingTypeClick(2)">FBA</el-button>
                  <el-button size="mini" @click="handleshippingTypeClick(3)">FBM</el-button>
                  <el-button size="mini" @click="handleshippingTypeClick(0)">无</el-button>
                  <el-tooltip effect="dark" content="亚马逊自营的产品，由亚马逊自营配送；第三方卖家销售但是由亚马逊负责配送的产品，属于FBA；第三方卖家销售并自发货的产品，属于FBM。如果黄金购物车空缺，则值为“无”" placement="top">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </el-row>

              </div>
              <div>
                标题同时包含：<el-input v-model="filterForm.title_include" placeholder="标题同时包含关键词" style="width: 400px"></el-input>
                标题同时排除：<el-input v-model="filterForm.title_exclude" placeholder="标题同时排除关键词" style="width: 400px"></el-input>
                <el-tooltip effect="dark" content="标题同时包含和排除" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </div>
            <div class="filter-btn">
              <el-button @click="ClearFilterForm" style="margin-right: 20px"><i class="el-icon-refresh-right"></i>重置筛选</el-button>
              <el-button type="primary" @click="fetchProducts"><i class="el-icon-search"></i>筛选</el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <div>
      <el-select v-model="sortord" placeholder="排序规则" @change="fetchProducts">
        <el-option label="月销量从高到低" value="salesCount_desc"></el-option>
        <el-option label="评论数从高到低" value="reviews_desc"></el-option>
        <el-option label="价格从高到低" value="price_desc"></el-option>
        <el-option label="上架时间从近到远" value="firstAvailable_desc"></el-option>
        <el-option label="评论数从低到高" value="reviews_order"></el-option>
        <el-option label="月销量从低到高" value="salesCount_order"></el-option>
        <el-option label="价格从低到高" value="price_order"></el-option>
        <el-option label="上架时间从远到近" value="firstAvailable_order"></el-option>
      </el-select>

      <!-- 导出按钮 -->
      <el-button @click="showExportPopover = true" icon="el-icon-download" style="margin-right: 20px">导出</el-button>

      <!-- 导出弹出框 -->
      <el-popover v-model="showExportPopover" placement="bottom" width="300" trigger="manual">
        <div>
          <el-switch v-model="downloadImg" active-text="导出主图（加载速度慢）"></el-switch>
          <el-tooltip effect="dark" content="导出当前条件筛选数据，支持最大导出前200条" placement="top">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
        <div style="text-align: right; margin-top: 10px;">
          <el-button size="mini" type="text" @click="showExportPopover = false">取消</el-button>
          <el-button type="primary" size="mini" @click="exportData" :loading="exportLoading">确认导出</el-button>
        </div>
      </el-popover>
      <span style="font-size: 12px">共 9,000,000+ 个活跃产品，此处展示 10,000 个结果</span>
    </div>
    <el-divider></el-divider>
    <div class="product-card">
      <el-card v-for="product in products" :key="product.id" :id="'card-' + product.id">
        <div style="position: relative">
          <el-button type="text" @click="toggleFavorite(product)">
            <i :class="{'el-icon-star-off': !isFavorited(product.id), 'el-icon-star-on': isFavorited(product.id)}"></i>
          </el-button>
          <el-tag
              v-if="product.trademark_status"
              size="mini"
              style="position: absolute; right: 0; top: 50%; transform: translateY(-50%)"
              :type="getTrademarkTagType(product.trademark_status)"
          >
            {{ product.trademark_status }}
          </el-tag>
        </div>
        <div class="thumbnail" style="display: flex; justify-content: center; align-items: center;">
          <el-tooltip :id="`tooltip-${product.id}`" placement="right" :visible-arrow="false" :offset="10">
            <img :src="product.mainImage" alt="thumbnail" width="110" height="110" />
            <!--            <img :src="product.mainImage.slice(0, -16) + '._AC_SS100_.jpg'"-->
            <!--                 alt="thumbnail"-->
            <!--                 width="100"-->
            <!--                 height="100"-->
            <!--                 @error="onImageError" />-->
            <template #content>
              <img :src="product.mainImage" alt="large image" style="width: 400px; height: 400px;" />
            </template>
          </el-tooltip>
        </div>
        <div class="title">
          <el-tooltip :content="product.title" placement="top">
            <span @click="goToAsinUrl(product.asin_url)" @mouseover="highlightTitle" @mouseout="unhighlightTitle" ref="titleRef" class="title-text" :class="{ 'highlight': isHighlighted }">{{ product.title }}</span>
          </el-tooltip>
        </div>
        <div class="rating">
          <el-rate :value="product.star" disabled class="rate"></el-rate>
          <span class="reviews">{{ product.reviews  | formatNumber }}</span>
        </div>
        <div>
          <div class="price reviews" >${{ product.price }}</div>
          <div class="price a_row" v-if="product.location === 'US'"><span class="title_colour">卖家所在地：</span>美国</div>
          <div class="price a_row" v-else-if="product.location === 'CN'"><span class="title_colour">卖家所在地：</span>中国</div>
          <div class="price a_row" v-else-if="product.location === 'CA'"><span class="title_colour">卖家所在地：</span>加拿大</div>
          <div class="price a_row" v-else-if="product.location === 'JP'"><span class="title_colour">卖家所在地：</span>日本</div>
          <div class="price a_row" v-else-if="product.location === 'ES'"><span class="title_colour">卖家所在地：</span>西班牙</div>
          <div class="price a_row" v-else-if="product.location === 'GB'"><span class="title_colour">卖家所在地：</span>英国</div>
          <div class="price a_row" v-else-if="product.location === 'DE'"><span class="title_colour">卖家所在地：</span>德国</div>
        </div>
        <div class="asin">
          <span class="title_colour">ASIN: </span>{{ product.asin }}
          <el-button type="text" size="mini" @click="copyAsin(product.asin)">复制</el-button>
        </div>
        <div class="rating card_title-text">
          <span class="seller"><span class="title_colour">卖家：</span>{{ product.sellerName }}</span>
        </div>
        <div class="rating card_title-text">
          <span class="a_row"><span class="title_colour">品牌：</span>{{ product.brandName }}</span>
        </div>
        <div>
          <el-tag size="mini" style="margin-right: 10px;" v-if="product.shippingType === 1">FBA</el-tag>
          <el-tag size="mini" style="margin-right: 10px;" v-else-if="product.shippingType === 2">FBM</el-tag>
          <el-tag size="mini" style="margin-right: 10px;">{{ product.sellerFollow }}个跟卖</el-tag>
          <el-tag size="mini" style="margin-right: 10px;">{{ product.subAsinCount }}个变体</el-tag>
        </div>
        <div class="info-box">
          <div><span class="title_colour">子ASIN近30天销量：</span>{{ product.boughtNum }}</div>
          <div><span class="title_colour">父ASIN月销量：</span>{{ product.salesCount | formatNumber }}</div>
          <div><span class="title_colour">父ASIN月销售额：</span>{{ product.sales | formatNumber }}</div>
          <el-tooltip :content="product.firstRankName" placement="top">
            <div class="card_title-text"><el-tag type="success"  size="mini"># {{ product.firstRank }}</el-tag> {{ product.firstRankName }}</div>
          </el-tooltip>
          <el-tooltip :content="product.otherRankName" placement="top">
            <div class="card_title-text"><el-tag type="success"  size="mini"># {{ product.otherRank }}</el-tag> {{ product.otherRankName }}</div>
          </el-tooltip>
        </div>
        <div class="listing-date card_title-text"><span class="title_colour">上架时间：</span>{{ product.firstAvailable }}</div>
        <div class="profit-margin card_title-text"><span class="title_colour">毛利率：</span>{{ product.profit }}%</div>
        <div class="fba-fee card_title-text"><span class="title_colour">FBA运费：</span>${{ product.fba }}</div>
        <div class="size-type card_title-text"><span class="title_colour">尺寸类型：</span>{{ product.size }}</div>
        <div class="weight card_title-text"><span class="title_colour">重量：</span>{{ product.weight }}lb</div>
        <el-tooltip :content="product.dimensions" placement="top">
          <div class="volume card_title-text"><span class="title_colour">体积：</span>{{ product.dimensions }}</div>
        </el-tooltip>
        <el-divider></el-divider>
        <el-popover
            placement="top-start"
            title="五点描述"
            width="500"
            trigger="click">
          <ul>
            <el-divider content-position="center">五点</el-divider>
            <li v-for="(item, index) in fivePointList" :key="index">{{ item }}</li>
          </ul>
          <ul>
            <el-divider content-position="center">描述</el-divider>
            <span class="a_row">{{ description }}</span>
          </ul>
          <el-button style="margin-right: 20px" size="mini" type="primary" slot="reference" @click="fetchDescription(product)">五点描述</el-button>
        </el-popover>
        <el-popover
            placement="top-start"
            title="品牌商标信息"
            width="500"
            trigger="click">
          <div v-if="brandInfo" class="brand-info-wrapper">
            <div class="brand-info-item">
              <span class="brand-info-label">品牌名称:</span>
              <span class="brand-info-value">{{ brandInfo.brandName }}</span>
            </div>
            <div class="brand-info-item">
              <span class="brand-info-label">国家/地区:</span>
              <span class="brand-info-value">
        <i class="el-icon-location"></i>
        {{ brandInfo.country }}
      </span>
            </div>
            <div class="brand-info-item">
              <span class="brand-info-label">商标状态:</span>
              <span class="brand-info-value">
        <span v-if="brandInfo.status === '死标'">
          <i class="el-icon-circle-close" style="color: red;"></i>
          {{ brandInfo.status }}
        </span>
        <span v-else-if="brandInfo.status === 'TM'">
          <i class="el-icon-tm" style="color: blue;"></i>
          {{ brandInfo.status }}
        </span>
        <span v-else-if="brandInfo.status === 'R'">
          <i class="el-icon-r" style="color: green;"></i>
          {{ brandInfo.status }}
        </span>
        <span v-else>
          <i class="el-icon-question" style="color: gray;"></i>
          {{ brandInfo.status || '无品牌' }}
        </span>
      </span>
            </div>
            <div class="brand-info-item">
              <el-tooltip effect="dark" placement="top">
                <div slot="content" v-html="tooltipContent"></div>
                <i class="el-icon-question"></i>
              </el-tooltip>
              <span class="brand-info-label">尼斯分类:</span>
              <div class="nice-class-wrapper">
                <el-tag v-for="item in brandInfo.niceClass" :key="item" size="small" style="margin-right: 5px;">
                  {{ item }}
                </el-tag>
              </div>
            </div>
          </div>
          <div v-else class="no-brand-info">
            <p>品牌商标信息查询中...</p>
          </div>
          <el-button class="brand-info-button" size="mini" type="primary" slot="reference" @click="look_brand(product)">品牌商标查询</el-button>
        </el-popover>
      </el-card>
    </div>
    <div>
      <el-backtop></el-backtop>
    </div>
    <div>
      <!-- 分页 -->
      <el-pagination
          :total="total"
          :page-size="limit"
          :page-sizes="[50, 100, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          class="pagination-right"
      >
      </el-pagination>
    </div>
  </div>

</template>
<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      favoritedProducts: [], // 存储已收藏商品的ID
      tooltipContent: "1.化学品<br>2.颜料、清漆、染料<br>3.化妆品、肥皂<br>4.润滑剂<br>5.工业用燃料和照明材料<br>6.金属材料<br>7.机器、机床、电动工具<br>8.手工工具和器具<br>9.科学仪器<br>10.医疗器械<br>11.照明、加热、蒸汽发生、烹饪、冷藏、干燥、通风、供水和卫生设备<br>12.车辆<br>13.武器、弹药<br>14.贵重金属制品和钟表<br>15.乐器<br>16.纸制品和办公用品<br>17.橡胶、塑料制品<br>18.皮革和模仿皮革制品<br>19.非金属建筑材料<br>20.家具、镜子、相框<br>21.家用或厨房用具和容器<br>22.绳索和纺织品<br>23.纱线和线<br>24.布料<br>25.服装<br>26.花边和刺绣、饰带和纽扣<br>27.地毯、地垫、席子、油毡、墙纸<br>28.游戏、玩具、体育用品<br>29.肉、鱼、家禽和野味、肉汤、腌制、冻干和烹调的水果和蔬菜、果冻、果酱、蛋、奶和乳制品<br>30.咖啡、茶、可可、糖、米、木薯、西米、咖啡代用品、面粉和谷类制品、面包、糕点和糖果、冰淇淋<br>31.农业、园艺、林业产品<br>32.啤酒、矿泉水和其他无酒精饮料、果汁和其他饮料<br>33.酒精饮料<br>34.烟草、烟具",
      // 品牌商标信息
      brandInfo: null,
      // 获取用户返回信息
      userInfo: {
        user_id: '',
        user_login: '',
        user_email: '',
        error: ''
      },
      // 列表数据导出
      showExportPopover: false,
      downloadImg: false,
      exportLoading: false,

      categories: [],
      selectedCategories: [],
      cascaderProps: {
        value: 'name',
        label: 'displayName',
        children: 'list',
        multiple: true,
        checkStrictly: true,
      },

      chartOptions: {},
      historyData: [],
      products: [],
      limit: 50, // 每页显示数量
      offset: 1, // 当前页码
      total: 0, // 总数据条数
      activeNames:  ['1'], // 默认展开的折叠面板
      isHighlighted: false,
      loading: false, // 是否加载中
      // 排序规则
      sortord: 'salesCount_desc',
      // 卖家所在地筛选
      location: '',
      // Buybox配送筛选
      shippingType: '',
      // 尺寸筛选
      size: '',
      // 五点描述
      fivePointList: [],
      description: '',
      // 搜索初始化
      searchForm: {
        asin: "",
        country_site: 'us',
        brandName: "",
        sellerName: "",
        sellerId: "",
        parentAsin: ""
      },
      // 筛选初始化
      filterForm: {
        salesCount: "",
        salesCount_min: "",
        salesCount_max: "",
        sales: "",
        sales_min: "",
        sales_max: "",
        profit: "",
        profit_min: "",
        profit_max: "",
        star: "",
        star_min: "",
        star_max: "",
        buybox: "",
        buybox_min: "",
        buybox_max: "",
        // 上架时间筛选
        listingTime: "",
        listingTime_start: "",
        listingTime_end: "",
        // 跟卖数筛选
        seller_follow: "",
        seller_follow_min: "",
        seller_follow_max: "",
        // 重量选择
        weight: "",
        weight_min: "",
        weight_max: "",
        // BRS选择
        brs: "",
        brs_min: "",
        brs_max: "",
        // 体积选择
        volume: "",
        volume_min: "",
        volume_max: "",
        // 变体数选择
        subAsinCount: "",
        subAsinCount_min: "",
        subAsinCount_max: "",
        // 标题同时包含关键词
        title_include: "",
        // 标题同时排除关键词
        title_exclude: "",
        // 类目筛选
        nodeNames: ""
      },
    };
  },
  mounted() {
    this.fetchProducts();
    this.fetchCategories();
    this.updateTitle();
  },
  methods: {
    getTrademarkTagType(status) {
      switch (status) {
        case '死标': return 'warning';
        case 'TM': return 'danger';
        case 'R': return 'danger';
        case '有效的国际注册': return 'info';
        case '无品牌': return 'success';
        default: return '';
      }
    },
    toggleFavorite(product) {
      if (this.isFavorited(product.id)) {
        this.removeFavorite(product);
      } else {
        this.addFavorite(product);
      }
    },
    isFavorited(productId) {
      return this.favoritedProducts.includes(productId);
    },
    async addFavorite(product) {
      const requestData = {
        user_id: Cookies.get("user_id"),
        user_name: Cookies.get("username"),
        community_user_name: Cookies.get("community_user_name"),
        community_user_phone: Cookies.get("community_user_phone"),
        country_site: this.searchForm.country_site,
        product_id: product.id,
        userid: Cookies.get("user_id"),
        tenant_id: Cookies.get("tenant_id"),
        custom_price: product.price * 7.2.toFixed(2),
        purchase_cost: product.price * 7.2 * 0.2.toFixed(2),
        fba_fee: product.fba,
        custom_weight: product.weight * 453.592,
        estimated_storage_time: 1,
      };

      try {
        const response = await this.$http.post('/v1/AddProductFavorite', requestData);
        if (response.data.msg === '收藏成功') {
          this.$message.success('收藏成功！');
          this.favoritedProducts.push(product.id); // 添加到已收藏列表
        } else {
          this.$message.error('收藏失败：' + response.data.msg);
        }
      } catch (error) {
        this.$message.error('收藏失败，请稍后重试！');
        console.error('收藏失败：', error);
      }
    },
    goBack() {
      window.location.href = 'https://www.zhiyoukuajing.com/';
    },
    updateTitle() {
      document.title = '亚马逊卖家联盟-大数据选品'
    },
    // 列表数据导出
    exportData() {
      this.exportLoading = true;

      const params = {
        tenant_id: Cookies.get("tenant_id"),
        user_id: Cookies.get("user_id"),
        user_name: Cookies.get("username"),
        community_user_name: Cookies.get("community_user_name"),
        community_user_phone: Cookies.get("community_user_phone"),
        download_img: this.downloadImg,
        Offset: this.offset,
        Limit: this.limit,
        "asin": this.searchForm.asin,
        "country_site": this.searchForm.country_site,
        "brandName": this.searchForm.brandName,
        "sellerName": this.searchForm.sellerName,
        "sellerId": this.searchForm.sellerId,
        "parentAsin": this.searchForm.parentAsin,
        "salesCount_min": this.filterForm.salesCount_min,
        "salesCount_max": this.filterForm.salesCount_max,
        "sales_min": this.filterForm.sales_min,
        "sales_max": this.filterForm.sales_max,
        "profit_min": this.filterForm.profit_min,
        "profit_max": this.filterForm.profit_max,
        "day1bsr_min": "",
        "day1bsr_max": "",
        "day7bsr_min": "",
        "day7bsr_max": "",
        "day15bsr_min": "",
        "day15bsr_max": "",
        "day30bsr_min": "",
        "day30bsr_max": "",
        "day1bsrAvg_min": "",
        "day1bsrAvg_max": "",
        "day7bsrAvg_min": "",
        "day7bsrAvg_max": "",
        "day15bsrAvg_min": "",
        "day15bsrAvg_max": "",
        "day30bsrAvg_min": "",
        "day30bsrAvg_max": "",
        "day1bsrChange_min": "",
        "day1bsrChange_max": "",
        "day7bsrChange_min": "",
        "day7bsrChange_max": "",
        "day15bsrChange_min": "",
        "day15bsrChange_max": "",
        "day30bsrChange_min": "",
        "day30bsrChange_max": "",
        "day1bsrRate_min": "",
        "day1bsrRate_max": "",
        "day7bsrRate_min": "",
        "day7bsrRate_max": "",
        "day15bsrRate_min": "",
        "day15bsrRate_max": "",
        "day30bsrRate_min": "",
        "day30bsrRate_max": "",
        "star_min": this.filterForm.star_min,
        "star_max": this.filterForm.star_max,
        "buybox_min": this.filterForm.buybox_min,
        "buybox_max": this.filterForm.buybox_max,
        "firstAvailable_start": this.filterForm.listingTime_start,
        "firstAvailable_end": this.filterForm.listingTime_end,
        "sellerFollow_min": this.filterForm.seller_follow_min,
        "sellerFollow_max": this.filterForm.seller_follow_max,
        "weight_min": this.filterForm.weight_min,
        "weight_max": this.filterForm.weight_max,
        "brs_min": this.filterForm.brs_min,
        "brs_max": this.filterForm.brs_max,
        "volume_min": this.filterForm.volume_min,
        "volume_max": this.filterForm.volume_max,
        "location": this.location,
        "shippingType": this.shippingType,
        "subAsinCount_min": this.filterForm.subAsinCount_min,
        "subAsinCount_max": this.filterForm.subAsinCount_max,
        "title_include": this.filterForm.title_include,
        "title_exclude": this.filterForm.title_exclude,
        "sortord": this.sortord,
        "nodeNames": this.filterForm.nodeNames
      };

      this.$http.post('/v1/GetAllBigDateSelectorWork', params, { responseType: 'blob' })
          .then(response => {
            console.log(response)
            if (response.data.type === "application/json"){
              this.$message.warning("当日下载次数已达上限")
              return
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '大数据选品数据导出.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            console.error('导出失败', error);
            if (error || "401"){
              this.$message.warning("当前会员权限无法访问该功能，详情请查看会员介绍页")
            }
          })
          .finally(() => {
            this.exportLoading = false;
            this.showExportPopover = false;
          });
    },
    async fetchCategories() {
      try {
        const response = await this.$http.get('/v1/Category');
        const categories = response.data.data.result;
        this.categories = this.formatCategories(categories);
      } catch (error) {
        console.error('获取类目失败:', error);
      }
    },
    formatCategories(categories) {
      return categories.map(category => ({
        ...category,
        displayName: `${category.name} ${category.aliasCn}`,
        list: this.formatCategories(category.list),
      }));
    },
    handleCategoryChange(selectedCategories) {
      // 将所选类目保存到数组中，可以在此处进行后端请求
      console.log('选中的类目:', selectedCategories);
      this.filterForm.nodeNames = selectedCategories
    },
    // 卖家所在地筛选
    handleLocationClick(value) {
      this.location = value;
    },
    // 尺寸筛选
    handleSizeClick(value) {
      this.size = value;
    },
    // Buybox配送筛选
    handleshippingTypeClick(value) {
      this.shippingType = value;
    },
    // 筛选按钮重置
    ClearFilterForm(){
      this.filterForm = {
        salesCount: "",
        salesCount_min: "",
        salesCount_max: "",
        sales: "",
        sales_min: "",
        sales_max: "",
        profit: "",
        profit_min: "",
        profit_max: "",
        star: "",
        star_min: "",
        star_max: "",
        buybox: "",
        buybox_min: "",
        buybox_max: "",
        // 上架时间筛选
        listingTime: "",
        listingTime_start: "",
        listingTime_end: "",
        // 跟卖数筛选
        seller_follow: "",
        seller_follow_min: "",
        seller_follow_max: "",
        // 重量选择
        weight: "",
        weight_min: "",
        weight_max: "",
        // BRS选择
        brs: "",
        brs_min: "",
        brs_max: "",
        // 体积选择
        volume: "",
        volume_min: "",
        volume_max: "",
        // 变体数选择
        subAsinCount: "",
        subAsinCount_min: "",
        subAsinCount_max: "",
        // 标题同时包含关键词
        title_include: "",
        // 标题同时排除关键词
        title_exclude: "",
        // 类目筛选
        nodeNames: ""
      }
      this.location = ''
      this.shippingType = ''
      this.fetchProducts()
    },
    // asin复制
    async copyAsin(asin) {
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', asin);
      document.body.appendChild(input);
      input.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.$message.success('复制成功');
        } else {
          this.$message.error('复制失败');
        }
      } catch (err) {
        this.$message.error('复制失败');
      }

      document.body.removeChild(input);
    },
    // 五点描述拆分
    fetchDescription(product) {
      const countrySite = this.searchForm.country_site;
      this.$http.post('/v1/GetDescription', {
        country_site: countrySite,
        product_id: product.id
      })
          .then(response => {
            if (response.data.state) {
              this.fivePointList = response.data.data.five_point.split(';;;;');
              this.description = response.data.data.description;
            } else {
              console.error('Failed to fetch description:', response.data.msg);
            }
          })
          .catch(error => {
            console.error('Error fetching description:', error);
          });
    },
    // 品牌商标查询
    look_brand(product) {
      this.brandInfo = ''
      const countrySite = this.searchForm.country_site;
      this.$http.post('/v1/GetLookBrand', {
        country_site: countrySite,
        brand_name: product.brandName
      })
          .then(response => {
            if (response.data.state) {
              this.brandInfo = response.data.data;
            } else {
              console.error('品牌商标查询失败:', response.data.msg);
            }
          })
          .catch(error => {
            console.error('品牌商标查询失败:', error);
          });
    },
    // 上架时间筛选
    handleListingTimeChange(value) {
      if (value === '') {
        this.filterForm.listingTime_start = "";
        this.filterForm.listingTime_end = "";
      } else if (value === '>48') {
        const fourYearsAgo = new Date();
        fourYearsAgo.setFullYear(fourYearsAgo.getFullYear() - 4);
        this.filterForm.listingTime_end = this.formatDate(fourYearsAgo);
        this.filterForm.listingTime_start = "";
      } else {
        const monthsAgo = new Date();
        monthsAgo.setMonth(monthsAgo.getMonth() - parseInt(value));
        this.filterForm.listingTime_start = this.formatDate(monthsAgo);
        this.filterForm.listingTime_end = "";
      }
    },
    // 上架时间筛选
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    // 处理月销量选择变更
    handleSalesCountChange(value) {
      if (value === "") {
        this.filterForm.salesCount_min = "";
        this.filterForm.salesCount_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.salesCount_min = min;
        this.filterForm.salesCount_max = max;
      }
    },

    // 处理月销售额选择变更
    handleSalesChange(value) {
      if (value === "") {
        this.filterForm.sales_min = "";
        this.filterForm.sales_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.sales_min = min;
        this.filterForm.sales_max = max;
      }
    },
    // 处理毛利率选择变更
    handleProfitChange(value) {
      if (value === null) {
        this.filterForm.profit_min = "";
        this.filterForm.profit_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.profit_min = min;
        this.filterForm.profit_max = max;
      }
    },
    // 处理star评分选择变更
    handleStarChange(value) {
      if (value === "") {
        this.filterForm.star_min = "";
        this.filterForm.star_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.star_min = min;
        this.filterForm.star_max = max;
      }
    },
    // 处理Buybox价格选择变更
    handleBuyboxChange(value) {
      if (value === null) {
        this.filterForm.buybox_min = "";
        this.filterForm.buybox_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.buybox_min = min;
        this.filterForm.buybox_max = max;
      }
    },
    // 处理跟卖数选择变更
    handlesellerFollowChange(value) {
      if (value === null) {
        this.filterForm.seller_follow_min = "";
        this.filterForm.seller_follow_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.seller_follow_min = min;
        this.filterForm.seller_follow_max = max;
      }
    },
    // 处理重量选择变更
    handleWeightChange(value) {
      if (value === '') {
        this.filterForm.weight_min = "";
        this.filterForm.weight_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.weight_min = min;
        this.filterForm.weight_max = max;
      }
    },
    // 处理BRS选择变更
    handleBRSChange(value) {
      if (value === '') {
        this.filterForm.brs_min = "";
        this.filterForm.brs_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.brs_min = min;
        this.filterForm.brs_max = max;
      }
    },
    // 处理体积选择变更
    handleVolumeChange(value) {
      if (value === '') {
        this.filterForm.volume_min = "";
        this.filterForm.volume_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.volume_min = min;
        this.filterForm.volume_max = max;
      }
    },
    // 处理变体数选择变更
    handlesubAsinCountChange(value) {
      if (value === '') {
        this.filterForm.subAsinCount_min = "";
        this.filterForm.subAsinCount_max = "";
      } else {
        const [min, max] = value.split('-');
        this.filterForm.subAsinCount_min = min;
        this.filterForm.subAsinCount_max = max;
      }
    },
    // 处理分页大小变更
    handleSizeChange(newSize) {
      this.limit = newSize;
      this.offset = 1; // 重置当前页码为1
      this.fetchProducts(); // 刷新列表
      this.$nextTick(() => {
        const cardId = 'card-' + this.products[0].id;
        const cardElement = document.getElementById(cardId);
        cardElement.scrollIntoView({ behavior: 'smooth' });
      });
    },

    // 处理当前页码变更
    handleCurrentChange(newPage) {
      this.offset = newPage;
      this.fetchProducts(); // 刷新列表
      this.$nextTick(() => {
        const cardId = 'card-' + this.products[0].id;
        const cardElement = document.getElementById(cardId);
        cardElement.scrollIntoView({ behavior: 'smooth' });
      });
    },
    async fetchProducts() {
      try {
        // 加载中
        this.loading = true;
        const apiUrl = `/v1/filter`;
        const postData = {
          tenant_id: Cookies.get("tenant_id"),
          user_id: Cookies.get("user_id"),
          user_name: Cookies.get("username"),
          community_user_name: Cookies.get("community_user_name"),
          community_user_phone: Cookies.get("community_user_phone"),
          Offset: this.offset,
          Limit: this.limit,
          "asin": this.searchForm.asin,
          "country_site": this.searchForm.country_site,
          "brandName": this.searchForm.brandName,
          "sellerName": this.searchForm.sellerName,
          "sellerId": this.searchForm.sellerId,
          "parentAsin": this.searchForm.parentAsin,
          "salesCount_min": this.filterForm.salesCount_min,
          "salesCount_max": this.filterForm.salesCount_max,
          "sales_min": this.filterForm.sales_min,
          "sales_max": this.filterForm.sales_max,
          "profit_min": this.filterForm.profit_min,
          "profit_max": this.filterForm.profit_max,
          "day1bsr_min": "",
          "day1bsr_max": "",
          "day7bsr_min": "",
          "day7bsr_max": "",
          "day15bsr_min": "",
          "day15bsr_max": "",
          "day30bsr_min": "",
          "day30bsr_max": "",
          "day1bsrAvg_min": "",
          "day1bsrAvg_max": "",
          "day7bsrAvg_min": "",
          "day7bsrAvg_max": "",
          "day15bsrAvg_min": "",
          "day15bsrAvg_max": "",
          "day30bsrAvg_min": "",
          "day30bsrAvg_max": "",
          "day1bsrChange_min": "",
          "day1bsrChange_max": "",
          "day7bsrChange_min": "",
          "day7bsrChange_max": "",
          "day15bsrChange_min": "",
          "day15bsrChange_max": "",
          "day30bsrChange_min": "",
          "day30bsrChange_max": "",
          "day1bsrRate_min": "",
          "day1bsrRate_max": "",
          "day7bsrRate_min": "",
          "day7bsrRate_max": "",
          "day15bsrRate_min": "",
          "day15bsrRate_max": "",
          "day30bsrRate_min": "",
          "day30bsrRate_max": "",
          "star_min": this.filterForm.star_min,
          "star_max": this.filterForm.star_max,
          "buybox_min": this.filterForm.buybox_min,
          "buybox_max": this.filterForm.buybox_max,
          "firstAvailable_start": this.filterForm.listingTime_start,
          "firstAvailable_end": this.filterForm.listingTime_end,
          "sellerFollow_min": this.filterForm.seller_follow_min,
          "sellerFollow_max": this.filterForm.seller_follow_max,
          "weight_min": this.filterForm.weight_min,
          "weight_max": this.filterForm.weight_max,
          "brs_min": this.filterForm.brs_min,
          "brs_max": this.filterForm.brs_max,
          "volume_min": this.filterForm.volume_min,
          "volume_max": this.filterForm.volume_max,
          "location": this.location,
          "shippingType": this.shippingType,
          "subAsinCount_min": this.filterForm.subAsinCount_min,
          "subAsinCount_max": this.filterForm.subAsinCount_max,
          "title_include": this.filterForm.title_include,
          "title_exclude": this.filterForm.title_exclude,
          "sortord": this.sortord,
          "nodeNames": this.filterForm.nodeNames,
          "size": this.size
        }
        const response = await this.$http.post(apiUrl, postData);
        const { data } = response;
        console.log(data.msg)
        if (data.msg === "当前会员权限无法访问该功能，详情请查看会员介绍页"){
          this.$message.warning('当前会员权限无法访问该功能，详情请查看会员介绍页');
          return
        }
        if (data.msg === "请核对智优跨境社区论坛账号或手机号是否正确"){
          this.$message.warning('请核对智优跨境社区论坛账号或手机号是否正确');
          return
        }
        if (data.msg === "该功能仅限智优跨境会员用户使用"){
          this.$message.warning('该功能仅限智优跨境会员用户使用');
          return
        }
        if (data.msg === "未找到该账号信息"){
          this.$message.warning('未找到该账号信息');
          return
        }
        this.products = data.data;
        this.total = response.data.count;
        this.$message.success('已为您找到 ' + data.count + '+ 个活跃商品');
      } catch (error) {
          this.$message.warning('未查到相应数据');

      } finally {
        // 结束加载状态
        this.loading = false;
      }
    },
    // 标题高亮
    highlightTitle(product) {
      product.isHighlighted = true;
      this.$nextTick(() => {
        const titleElement = this.$refs.titleRef.find(el => el.dataset.id === product.id);
        product.showTitleTooltip = titleElement.offsetWidth < titleElement.scrollWidth;
      });
    },
    unhighlightTitle(product) {
      product.isHighlighted = false;
    },
    goToAsinUrl(url) {
      window.open(url, '_blank');
    }
  },
  // vue计算属性
  filters: {
    // 千位符计算
    formatNumber(value) {
      if (value === null || value === undefined) {
        return ''; // or return a default value or handle the case when value is null
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
};
</script>

<style scoped>
.product-card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.el-card {
  flex: 1 0 calc(16.5% - 16px);
  max-width: calc(16.5% - 16px);
  margin-bottom: 5px;
}

@media screen and (max-width: 1200px) {
  .el-card {
    flex: 1 0 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
}

@media screen and (max-width: 992px) {
  .el-card {
    flex: 1 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
}

@media screen and (max-width: 768px) {
  .el-card {
    flex: 1 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media screen and (max-width: 576px) {
  .el-card {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.large-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.title {
  position: relative;
}

.title-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1;
}

.info-box {
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 10px;
}
.el-card {
  font-size: 0.8rem; /* reduce font size to 0.8rem */
}

.el-card .title {
  font-size: 1rem; /* reduce font size to 1rem */
}

.el-card .info-box {
  font-size: 0.8rem; /* reduce font size to 0.9rem */
}

.thumbnail {
  position: relative;
}

.large-image {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


/* 标题高亮+链接跳转 */
.title {
  position: relative;
  cursor: pointer;
}

.title-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-text.highlight {
  background-color: #e6f7ff;
  color: #1890ff;
}

/* 搜索+筛选 */

.search-module {
  margin-bottom: 20px;
}

.search-module .el-input {
  width: 200px;
  margin-right: 10px;
}

.filter-module {
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-label {
  margin-right: 10px;
}

.filter-item .el-select {
  width: 120px;
  margin-right: 10px;
}

.filter-item .el-input {
  width: 100px;
  margin-right: 10px;
}

.el-icon-question {
  margin-left: 5px;
  cursor: pointer;
}

/* 搜索+筛选样式 */
.search-module {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.search-container .el-input {
  margin: 10px;
  width: 200px;
}

.search-container .el-button {
  margin: 10px;
}

.filter-container {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  border: none;
}

.filter-module {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-item {
  display: flex;
  align-items: center;
  margin: 10px;
}

.filter-label {
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.filter-item .el-select {
  width: 150px;
  margin-right: 10px;
}

.filter-item .el-input {
  width: 150px;
  margin-right: 10px;
}

.filter-item .el-tooltip {
  margin-left: 10px;
}

.filter-item .el-date-picker {
  width: 150px;
  margin-right: 10px;
}

.filter_text{
  color: #2396fa;
}


.filter-module {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-item {
  margin-bottom: 20px;
}

.filter-btn {
  margin-top: 20px;
  text-align: center;
}

.filter-btn .el-button {
  width: 100px;
  margin: 0 auto;
}


/* 确认筛选按钮样式 */
.filter-module {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-item {
  margin-bottom: 20px;
}

.filter-btn {
  margin-top: 20px;
  text-align: center;
}

.filter-btn .el-button {
  width: 100px;
  margin: 0 auto;
}


/* 搜索栏样式 */
.search-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.el-input, .el-select {
  margin-right: 10px;
}

.el-input:last-child, .el-select:last-child {
  margin-right: 0;
}

/* 分页组件靠右显示 */
.pagination-right {
  float: right;
}

/* 标题栏高亮蓝色显示 */
.title-text:hover {
  color: #337ab7; /* blue color */
  text-decoration: underline; /* add an underline effect */
  cursor: pointer; /* change the cursor to a pointer */
}

/* 卡片文字易超出部分限制行数 */
.card_title-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 星级和评价显示在一行 */
.rating {
  display: flex;
  align-items: center;
}

.rate {
  display: inline-block;
}

.reviews {
  display: inline-block;
  margin-left: 10px;
  font-size: 19px;
}

/* 通用设置文字靠最右显示 */
.a_row {
  display: inline-block;
  float: right;
}


.brand-info-wrapper {
  padding: 10px;
}

.brand-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.brand-info-label {
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.brand-info-value {
  display: flex;
  align-items: center;
}

.nice-class-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.no-brand-info {
  text-align: center;
  padding: 20px;
  color: #999;
}

.brand-info-button {
  margin-top: 10px;
}


/* 品牌商标信息 */
.brand-info-wrapper {
  padding: 10px;
}

.brand-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.brand-info-label {
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.brand-info-value {
  display: flex;
  align-items: center;
}

.nice-class-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.no-brand-info {
  text-align: center;
  padding: 20px;
  color: #999;
}

.brand-info-button {
  margin-top: 10px;
}

.el-icon-star-on {
  color: #f56c6c; /* 收藏状态颜色 */
}

.title_colour{
  color: #87888c; /* 卡片头描述颜色 */
}
</style>
