<template>
  <div>
    <!--    面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcom' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/MenuManage' }">系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    卡片视图-->
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
          <!--搜索-->
          <el-input placeholder="支持搜索子菜单 ID | 名称 | 请求地址" v-model="menu_list.quy">
            <el-button slot="append" icon="el-icon-search" @click="GetMenuList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--      表格列表-->
      <el-table :data="menu_lists" border style="width: 100%" stripe highlight-current-row clearSort max-height="600"
                row-key="name" :tree-props="{children: 'children'}" lazy>
        <el-table-column type="selection" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column type="index" label="序号" align="center" width="80" sortable show-overflow-tooltip></el-table-column>
        <el-table-column prop="id" label="菜单ID" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag
                :type="scope.row.menu_type === 1 ? 'primary' : 'success'"
                disable-transitions>{{scope.row.id}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag
                :type="scope.row.menu_type === 1 ? 'primary' : 'success'"
                disable-transitions>{{scope.row.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="请求地址" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="menu_type" label="菜单类型" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.menu_type===1">目录</el-tag>
            <el-tag v-if="scope.row.menu_type===2" type="success">菜单</el-tag>
            <el-tag v-if="scope.row.menu_type===3" type="info">按钮</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="图标" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center" sortable></el-table-column>
        <el-table-column prop="enable" label="状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch v-model="scope.row.visible"
                       :active-value=1
                       :inactive-value=0
                       active-color="#13ce66"
                       inactive-color="#ff4949" @change="MenuStateChange(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="user_name" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="danger"
                @click="MenuDelete(scope.row)" disabled>删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  data(){
    return{
      user_infos:{
        user_name: null,
        menu_type: null
      },
      menu_list:{
        quy:'',
      },
      menu_lists:[],
      tota:0,
      // 删除菜单
      DeleteMenu: {
        menu_type: null,
        menu_id: null,
      },
      // 菜单状态设置
      DisableMenu: {
        menu_type: null,
        menu_id: null,
        state: null,
      },
      //搜索数据报错
      MenuSearchData: "",
    }
  },

  created() {
    this.GetMenuList()
  },
  methods:{
    // 获取菜单列表
    GetMenuList(){
      this.$http.get('/v1/GetMenuAll').then(response => {
        const { data } = response;
        if (data && data.code === 200) {
          this.menu_lists = data.data;
          // 如果存在搜索词，则执行搜索过滤
          if(this.menu_list.quy){
            this.menu_lists = this.filterMenu(this.menu_lists, this.menu_list.quy);
          }
        } else {
          this.$message.error('菜单列表获取失败');
        }
      }).catch(error => {
        console.error('请求菜单列表发生错误：', error);
        this.$message.error('请求菜单列表发生错误');
      });
    },

    // 搜索过滤器
    filterMenu(menuList, query){
      // 递归过滤菜单数据
      function filterRecursive(list){
        return list.filter(menu => {
          // 检查当前菜单项是否匹配搜索词
          if(menu.id.toString().includes(query) || menu.name.includes(query) || (menu.url && menu.url.includes(query))){
            return true;
          }
          // 如果存在子菜单，递归过滤子菜单
          if(menu.children && menu.children.length){
            menu.children = filterRecursive(menu.children);
            // 如果子菜单中有匹配项，则保留当前菜单
            return menu.children.length > 0;
          }
          // 当前菜单项不匹配搜索词
          return false;
        });
      }

      // 调用递归过滤函数开始过滤
      return filterRecursive(menuList);
    },
    //删除按钮
    MenuDelete(menu_info){
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(menu_info.name)
        this.DeleteMenu.menu_type = menu_info.menu_type
        this.DeleteMenu.menu_id = menu_info.id
        this.$http.post('/v1/DeleteMenu',this.DeleteMenu).then(data => {
          console.log(data.data["msg"])
          if (data.data["msg"] === "删除成功"){
            this.$message.success('删除成功');
            this.GetMenuList()
          }else {
            this.$message.error(data.data["msg"]);
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //监听switch开关状态的改变
    MenuStateChange(menuinfo){
      console.log(menuinfo);
      this.DisableMenu.state = menuinfo.visible
      this.DisableMenu.menu_type = menuinfo.menu_type
      this.DisableMenu.menu_id = menuinfo.id
      this.$http.put('/v1/DisableMenu',this.DisableMenu).then(data => {
        console.log(data.data["msg"])
        if (data.data["msg"] === "修改成功"){
          this.$message.success('修改成功');
        }else {
          this.$message.error(data.data["msg"]);
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
