<template>
  <div class="container">
    <h1 class="title">商标注册查询工具</h1>
    <div class="site-selection">
      <el-radio-group v-model="selectedSite">
        <el-radio-button label="US">美国</el-radio-button>
        <el-radio-button label="CN">中国</el-radio-button>
        <el-radio-button label="UK">英国</el-radio-button>
        <el-radio-button label="DE">德国</el-radio-button>
        <el-radio-button label="FR">法国</el-radio-button>
        <el-radio-button label="IT">意大利</el-radio-button>
        <el-radio-button label="ES">西班牙</el-radio-button>
        <el-radio-button label="MX">墨西哥</el-radio-button>
        <el-radio-button label="CA">加拿大</el-radio-button>
        <el-radio-button label="JP">日本</el-radio-button>
        <el-radio-button label="IN">印度</el-radio-button>
        <el-radio-button label="AU">澳大利亚</el-radio-button>
      </el-radio-group>
    </div>
    <div class="search-input">
      <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入搜索条件，每行一个"
          v-model="searchText"
          style="margin-right: 30px"
      ></el-input>
      <el-button type="primary" @click="search" style="width: 200px" :loading="loading" :disabled="loading">精准搜索</el-button>
      <el-button type="success" @click="exportData" style="width: 100px; margin-left: 10px" :loading="exportLoading" :disabled="loading">
        <i class="el-icon-download"></i> 导出
      </el-button>
    </div>
    <div class="search-result">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column prop="site" label="站点"></el-table-column>
        <el-table-column prop="brand" label="品牌信息"></el-table-column>
        <el-table-column prop="link" label="商标局跳转">
          <template slot-scope="scope">
            <a :href="scope.row.link" target="_blank">{{ scope.row.link }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="本土注册状态"></el-table-column>
        <el-table-column prop="category" label="受保护类目"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      exportLoading: false,
      selectedSite: 'US',
      searchText: '',
      tableData: [],
      loading: false
    };
  },
  methods: {
    async exportData() {
      if (this.searchText.trim() === '') {
        this.$message.warning('请填写品牌信息');
        return;
      }
      this.exportLoading = true;
      const searchConditions = this.searchText
          .split('\n')
          .filter(line => line.trim() !== '')
          .map(line => {
            const [brandName, country] = line.split(',');
            return { brandName: brandName.trim(), country: country ? country.trim() : this.selectedSite };
          });

      try {
        const response = await this.$http.post('/v1/ExportBrandList', {
          user_id: Cookies.get("user_id"),
          user_name: Cookies.get("username"),
          community_user_name: Cookies.get("community_user_name"),
          community_user_phone: Cookies.get("community_user_phone"),
          brand_list: searchConditions
        }, { responseType: 'blob' }); // 指定响应类型为 blob

        if (response.data.type === 'application/json') { // 判断是否返回的是错误信息
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = JSON.parse(e.target.result);
            this.$message.error(data.msg || '导出失败');
          };
          reader.readAsText(response.data);
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'brand_list.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error('导出异常:', error);
        this.$message.error('导出失败，请稍后重试');
      } finally {
        this.exportLoading = false;
      }
    },
    async search() {
      if (this.searchText.trim() === '') {
        this.$message.warning('请填写品牌信息');
        return;
      }
      const searchConditions = this.searchText
          .split('\n')
          .filter(line => line.trim() !== '') // 过滤掉空行
          .map(line => {
            const [brandName, country] = line.split(',');
            return { brandName: brandName.trim(), country: country ? country.trim() : this.selectedSite };
          });

      try {
        this.loading = true; // 发送请求前将 loading 设置为 true
        const response = await this.$http.post('/v1/GetLookBrandList', { tenant_id: Cookies.get("tenant_id"),user_id: Cookies.get("user_id"),
          user_name: Cookies.get("username"), community_user_name: Cookies.get("community_user_name"),
          community_user_phone: Cookies.get("community_user_phone"), brand_list: searchConditions });
        if (response.data.code === 200) {
          this.tableData = response.data.data.map((item, index) => ({
            site: this.selectedSite,
            brand: searchConditions[index].brandName,
            link: `https://branddb.wipo.int/`,
            status: item.status,
            category: item.niceClass.join(', ')
          }));
        } else {
          console.error('请求失败:', response.data.msg);
          this.$message.warning(response.data.msg);
          // 显示错误提示
        }
      } catch (error) {
        console.error('请求异常:', error);
        // 显示异常提示
      }finally {
        this.loading = false; // 请求完成后将 loading 设置回 false
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.site-selection {
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  margin-bottom: 20px;
}

.search-input .el-input {
  flex: 1;
  margin-right: 10px;
}

.search-input .el-button {
  width: 100px;
}
</style>
