import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import login from './views/login.vue'
import home from './views/home.vue'
import welc from './views/Welcom.vue'
import user from './views/user.vue'
import LoginLog from './views/LoginLog.vue'
import Role from './views/Role.vue'
import MenuManage from './views/MenuManage.vue'
import ChatList from "@/views/ProductFavorites.vue"
import Artist from "@/views/Artist.vue"
import ShopCheckASIN from "@/views/ShopCheckASIN.vue"
import brand from "@/views/brand.vue"
import TenantAccount from "@/views/TenantAccount.vue"
import member from "@/views/member.vue"
import AccessConfig from "@/views/AccessConfig.vue"
import TenantManagement from "@/views/TenantManagement.vue"
import Order from "@/views/SaleItem.vue"
import AsinTheStore from "@/views/AsinTheStore.vue"
import SalesDataReport from "@/views/SalesDataReport.vue"
import Permission from "@/views/Permission.vue"
import datas from "@/views/datas.vue"
import ProductFavorites from "@/views/ProductFavorites.vue"
import GatherUser from "@/views/GatherUser.vue"

Vue.use(VueRouter)


const routes: Array<RouteConfig> = [
  {
    path: '/',
    // name: 'Home',
    redirect: '/login',
  },
  {
    path: '/login',
    // name: 'Home',
    component: login
  },
  {
    path: '/home',
    component: home,
    // redirect:'/welcom',
    children:[
        {path:'/welcom',component:welc},
        {path:'/userManagement',component:user},
        {path:'/LoginLog',component:LoginLog},
        {path:'/roleManagement',component:Role},
        {path:'/MenuManage',component:MenuManage},
        {path:'/ChatList',component:ChatList},
        {path:'/Artist',component:Artist},
        // 亚马逊店铺找ASIN
        {path:'/ShopCheckASIN',component:ShopCheckASIN},
        // 品牌商标查询
        {path:'/brand',component:brand},
        {path:'/TenantAccount',component:TenantAccount},
        // 个人中心
        {path:'/member',component:member},
        // 访问权限配置
        {path:'/AccessConfig',component:AccessConfig},
        {path:'/TenantManagement',component:TenantManagement},
        {path:'/Order',component:Order},
        // 亚马逊ASIN查店铺
        {path:'/AsinTheStore',component:AsinTheStore},
        {path:'/SalesDataReport',component:SalesDataReport},
        {path:'/Permission',component:Permission},
        // 大数据选品页
        {path:'/datas',component:datas},
        // 个人产品收藏夹
        {path:'/ProductFavorites',component:ProductFavorites},
        // 采集账号管理
        {path:'/GatherUser',component:GatherUser},
    ]
  },
]



const router = new VueRouter({
    mode: 'history',
    routes


})

router.beforeEach((to, from, next) => {
  if (to.path == '/login') return next()
  const  tokenstr = window.sessionStorage.getItem('token')
  if (!tokenstr) return next('/login')
  next()
})

export default router
