<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcom' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/Artist' }">艺术家管理</el-breadcrumb-item>
      <el-breadcrumb-item>艺术家列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <!-- 搜索艺术家姓名 -->
          <el-input placeholder="搜索艺术家姓名" v-model="reqData.ArtistName" clearable @clear="resetAndRequest">
            <el-button slot="append" icon="el-icon-search" @click="getAllArtists"></el-button>
          </el-input>
        </el-col>

        <el-col :span="6" style="text-align: right;">
        </el-col>
        <!-- 添加艺术家按钮 -->
        <el-button type="primary" @click="showAddArtistDialog">添加艺术家</el-button>
      </el-row>

      <!-- 添加艺术家对话框 -->
      <el-dialog
          :visible.sync="addArtistDialogVisible"
          title="添加艺术家信息"
          width="30%"
          :before-close="handleDialogClose">
        <el-form :model="newArtist" :rules="rules" label-width="80px" ref="newArtistForm">
          <el-form-item label="艺术家姓名" prop="name">
            <el-input v-model="newArtist.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="艺术方向" prop="category">
            <el-input v-model="newArtist.category" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="description">
            <el-input type="textarea" v-model="newArtist.description" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>

        <!-- 操作按钮 -->
        <div slot="footer">
          <el-button @click="addArtistDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addArtist">确定</el-button>
        </div>
      </el-dialog>

      <!-- 艺术家列表 -->
      <el-table :data="artistsList" border stripe highlight-current-row style="width: 100%">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="艺术家姓名" width="180"></el-table-column>
        <el-table-column prop="category" label="艺术方向" width="180"></el-table-column>
        <el-table-column prop="description" label="备注"></el-table-column>
        <el-table-column prop="create_time" label="添加时间" width="220" sortable></el-table-column>
        <el-table-column fixed="right" label="操作" width="90">
          <template v-slot="{ row }">
            <el-button type="danger" size="mini" @click="removeArtist(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
          :current-page.sync="reqData.Offset"
          :page-size.sync="reqData.Limit"
          :total="totalArtists"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ArtistManagement",
  data() {
    return {
      reqData: {
        Offset: 1, // 请求的当前页码
        Limit: 10, // 请求的每页数量
        ArtistName: "" // 请求的艺术家姓名
      },
      newArtist: {
        name: "", // 新增艺术家的姓名
        category: "", // 新增艺术家的艺术方向
        description: "" // 新增艺术家的备注信息
      },
      artistsList: [], // 艺术家列表数据
      loading: false, // 请求数据时的加载状态
      addArtistDialogVisible: false, // 控制添加艺术家对话框的显示
      totalArtists: 0, // 艺术家总数用于分页
      rules: { // 表单验证规则
        name: [{ required: true, message: '请输入艺术家姓名', trigger: 'blur' }],
        category: [{ required: true, message: '请输入艺术方向', trigger: 'blur' }]
      }
    };
  },
  created() {
    this.getAllArtists()
  },
  methods: {
    getAllArtists() {
      // 请求获取所有艺术家信息
      this.loading = true;
      // 这里我们模拟后端接口地址为"/v1/GetAllArtists"，实际请按后端实际调整
      this.$http.post('/v1/GetAllArtists', this.reqData).then(response => {
        const { data } = response;
        if (data.state) {
          this.totalArtists = data.count;
          this.artistsList = data.data;
        } else {
          this.$message.error('获取艺术家列表失败');
        }
      }).catch(() => {
        this.$message.warning('未查到数据');
      }).finally(() => {
        this.loading = false;
      });
    },
    showAddArtistDialog() {
      // 显示添加艺术家对话框
      this.addArtistDialogVisible = true;
    },
    handleDialogClose(done) {
      // 处理对话框关闭
      this.$refs.newArtistForm.resetFields();
      done();
    },
    addArtist() {
      // 添加艺术家
      this.$refs.newArtistForm.validate(valid => {
        if (valid) {
          // 表单验证通过后执行添加操作，实际后端接口地址请替换为你的实际地址
          this.$http.post('/v1/AddArtist', this.newArtist)
              .then(response => {
                if (response.data.state) {
                  this.$message.success('添加成功');
                  this.getAllArtists();
                  this.addArtistDialogVisible = false;
                } else {
                  this.$message.error(response.data.message);
                }
              });
        }
      });
    },
    removeArtist(row) {
      this.$confirm('这将永久删除该艺术家, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 实际后端接口地址请替换为你的实际地址
        this.$http.post('/v1/RemoveArtist', { artist_id: row.id })
            .then(response => {
              if (response.data.state) {
                this.$message.success('删除成功');
                this.getAllArtists();
              } else {
                this.$message.error(response.data.message);
              }
            });
      });
    },
    handleSizeChange(newSize) {
      // 处理页数大小改变时的操作
      this.reqData.Limit = newSize;
      this.getAllArtists();
    },
    handleCurrentChange(newPage) {
      // 处理当前页改变时的操作
      this.reqData.Offset = newPage;
      this.getAllArtists();
    },
    resetAndRequest() {
      // 重置搜索条件并重新请求数据
      this.reqData.Offset = 1;
      this.getAllArtists();
    }
  }
};
</script>

<!-- 自定义组件的样式可以放在这里 -->
<style scoped>
</style>