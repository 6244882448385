<template>
  <div>
    <!--    面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcom' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/LoginLog' }">系统监控</el-breadcrumb-item>
      <el-breadcrumb-item>登录日志</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    卡片视图-->
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
          <!--日志搜索-->
          <el-input placeholder="请输入内容" v-model="userlist.quy">
            <el-button slot="append" icon="el-icon-search" @click="getuserlist"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--      表格列表-->
      <el-table :data="log_lists" border style="width: 100%" stripe highlight-current-row clearSort max-height="500">
        <el-table-column type="selection" align="center" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column prop="id" label="序号" width="90" align="center" sortable show-overflow-tooltip></el-table-column>
        <el-table-column prop="uid" label="uid" align="center" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="desc" label="Desc" width="90" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="url" label="URL" align="center" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="method" label="Method" align="center" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="ip" label="IP" align="center"  width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="success" label="Success" align="center" width="90" show-overflow-tooltip></el-table-column>
        <el-table-column prop="user_agent" label="user_agent" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_time" label="登录时间" align="center" width="180" sortable></el-table-column>
<!--        <el-table-column prop="user_name" align="center" label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-->
<!--                size="mini"-->
<!--                type="danger"-->
<!--                @click="handleDelete(scope.row)" disabled>删除-->
<!--            </el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <!--      页码-->
      <el-pagination
          :page-sizes="[10, 20, 50, 100]"
          :page-size="10"
          background
          layout="total,sizes,prev, pager, next,jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="tota">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data(){
    return{
      userlist:{
        quy:'',
        Limit:10,
        Offset:1,
      },
      log_lists:[],
      tota:0,
      // 删除用户
      DeleteUser: {
        username: ""
      },
    }
  },

  created() {
    this.getuserlist()
  },
  methods:{
    getuserlist(){
      this.$http.post('/v1/LoginLog',this.userlist).then(data => {
        console.log(data.data.data)
        // console.logs(data.data.total_count)
        this.log_lists = data.data["data"]
        this.tota = data.data["count"]
        if (data["data"]["msg"] !== "获取成功"){
          return this.$message.error('登录日志列表获取失败')
        }
      })

    },
    //分页上一页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.userlist.Limit = val
      this.$http.post('/v1/LoginLog',this.userlist).then(data => {
        // console.logs(data.data.data)
        // console.logs(data.data.total_count)
        this.log_lists = data.data["data"]
        this.tota = data.data["count"]
      })
    },
    //分页下一页
    handleCurrentChange(val) {
      this.userlist.Offset = val
      console.log(`当前页: ${val}`);
      this.$http.post('/v1/LoginLog',this.userlist).then(data => {
        this.log_lists = data.data["data"]
        this.tota = data.data["count"]
      })
    },
  }


}
</script>

<style scoped>
</style>
