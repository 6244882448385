<template>
  <div class="order-management">
    <!-- 销售订单导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcom' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/salesOrderManagement' }">销售订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>销售订单列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部操作栏 -->
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <!-- 搜索栏 -->
          <el-input placeholder="订单号或操作人" v-model="searchQuery.keywords" clearable @clear="getOrderList" @keyup.enter.native="getOrderList">
            <el-button slot="append" icon="el-icon-search" @click="getOrderList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <!-- 新增销售订单按钮 -->
          <el-button type="primary" @click="showOrderDialog">新增销售订单</el-button>
        </el-col>
      </el-row>
    </el-card>

    <!-- 销售订单列表 -->
    <el-table :data="salesOrderList" stripe v-loading="loading" style="width: 100%;">
      <el-table-column prop="order_number" label="销售单号"></el-table-column>
      <el-table-column prop="customer_name" label="客户"></el-table-column>
      <el-table-column prop="total_amount" label="总金额"></el-table-column>
      <el-table-column prop="status" label="订单状态"></el-table-column>
      <el-table-column prop="create_time" label="销售日期" width="180"></el-table-column>
      <el-table-column prop="note" label="备注"></el-table-column>
<!--      <el-table-column fixed="right" label="操作" width="150">-->
<!--        <template v-slot="{ row }">-->
<!--          <el-button size="mini" @click="viewOrderDetails(row)">查看</el-button>-->
<!--          <el-button size="mini" type="danger" @click="deleteOrder(row.order_id)">删除</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>

    <!-- 分页 -->
    <el-pagination
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="paginationChange"
        @size-change="paginationSizeChange">
    </el-pagination>

    <!-- 新增/编辑销售订单对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="isOrderDialogVisible" width="70%">
      <el-form :model="orderForm" ref="orderForm" label-width="100px">
        <el-form-item label="销售单号">
          <el-input v-model="orderForm.order_number" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="艺术品" prop="artwork_id">
          <el-select
              v-model="orderForm.artwork_id"
              filterable
              remote
              reserve-keyword
              placeholder="请输入艺术品名称搜索"
              :remote-method="searchArtwork"
              :loading="artworkLoading">
            <el-option
                v-for="item in artworkList"
                :key="item.id"
                :label="item.artwork_name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 客户选择 -->
        <el-form-item label="客户" prop="customer_id">
          <el-select
              v-model="orderForm.customer_id"
              filterable
              remote
              reserve-keyword
              placeholder="请输入客户名称搜索"
              :remote-method="searchCustomers"
              :loading="customerLoading">
            <el-option
                v-for="item in customersList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款方式" prop="payment_method">
          <el-select v-model="orderForm.payment_method" placeholder="请选择">
            <el-option label="转账" value="transfer"></el-option>
            <el-option label="现金" value="cash"></el-option>
            <!-- 如有更多收款方式，继续添加 -->
          </el-select>
        </el-form-item>
        <el-form-item label="操作人" prop="operator">
          <el-input v-model="orderForm.operator"></el-input>
        </el-form-item>
        <el-form-item label="订单备注" prop="note">
          <el-input v-model="orderForm.note" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="订单状态" prop="status">
          <el-select v-model="orderForm.status" placeholder="请选择">
            <el-option label="已确认" value="confirmed"></el-option>
            <el-option label="待确认" value="unconfirmed"></el-option>
            <!-- 如有更多状态，继续添加 -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addSaleItem">添加商品</el-button>
        </el-form-item>
        <el-table :data="saleItemList" style="width: 100%" max-height="300" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="artwork_name" label="艺术品名称" width="180"></el-table-column>
          <el-table-column label="销售价格" width="120">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.sale_price" placeholder="请输入销售价格" />
            </template>
          </el-table-column>
          <el-table-column label="数量" width="100">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.quantity" placeholder="请输入数量" />
            </template>
          </el-table-column>
          <el-table-column label="税额" width="100">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.tax" placeholder="请输入税额" />
            </template>
          </el-table-column>
          <el-table-column label="总价" width="120">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.total_price" placeholder="请输入总价" />
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isOrderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitOrder">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: { keywords: '' },
      pagination: { currentPage: 1, pageSize: 10, total: 0 },
      loading: false,
      salesOrderList: [],
      isOrderDialogVisible: false,
      dialogTitle: '新增销售订单',
      orderForm: {
        order_number: '',
        artwork_id: null,
        customer_id: null,
        payment_method: '',
        operator: '',
        note: '',
        status: 'confirmed'
      },
      customersList: [], // 由API填充
      artworkList: [], // 由API填充
      saleItemList: [], // 销售商品记录
      artworkLoading: false, // 用于显示加载中的状态
      customerLoading: false, // 控制加载状态
    };
  },
  created() {
    this.getOrderList();
  },
  watch: {
    // 监视对话框的可见性
    isOrderDialogVisible(newValue, oldValue) {
      // 当对话框由可见变为不可见时，重新获取订单列表
      if (oldValue === true && newValue === false) {
        this.getOrderList();
      }
    }
  },
  methods: {
    getOrderList() {
      const params = {
        Offset: this.pagination.currentPage, // 使用当前页面数据
        Limit: this.pagination.pageSize, // 使用当前页面大小
        order_number: this.searchQuery.keywords,
        operator: this.searchQuery.keywords,
        status: '' // 这里应包括订单状态的查询参数，如果有的话
      };
      this.loading = true; // 设置加载状态为true
      this.$http.post('/v1/GetAllOrders', params).then(response => {
        this.loading = false; // 请求完成后，无论成功与否，都将加载状态设置为false
        if (response.data && response.data.code === 200) {
          this.salesOrderList = response.data.data; // 设置表格数据
          this.pagination.total = response.data.count; // 设置总记录数，这里假设总记录数字段是count
        } else {
          this.$message.error('获取订单列表失败：' + response.data.msg);
        }
      }).catch(error => {
        this.loading = false;
        this.$message.warning('列表无数据');
      });
    },
    showOrderDialog() {
      this.resetOrderForm();
      this.dialogTitle = '新增销售订单';
      this.isOrderDialogVisible = true;
    },
    resetOrderForm() {
      this.orderForm = {
        order_number: this.generateOrderNumber(),
        artwork_id: null,
        customer_id: null,
        payment_method: '',
        operator: '',
        note: '',
        status: 'confirmed'
      };
      this.saleItemList = [];
    },
    generateOrderNumber() {
      // 获取当前日期的年月日时分秒
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      // 生成4位随机数序列
      const randomSequence = Math.floor(Math.random() * 10000).toString().padStart(4, '0');

      // 拼接以上部分作为订单号
      const orderNumber = `${year}${month}${day}${hours}${minutes}${seconds}${randomSequence}`;

      return orderNumber;
    },
    paginationChange(newPage) {
      this.pagination.currentPage = newPage; // 更新当前页码
      this.getOrderList(); // 重新获取数据
    },
    paginationSizeChange(newSize) {
      this.pagination.currentPage = 1; // 重置为第一页
      this.pagination.pageSize = newSize; // 更新页面大小
      this.getOrderList(); // 重新获取数据
    },
    submitOrder() {
      // 验证订单中是否至少有一项销售商品
      if (this.saleItemList.length === 0) {
        this.$message({ type: 'warning', message: '销售订单中至少需要一项商品' });
        return;
      }

      // 准备请求体，将销售商品列表作为一个数组发送
      const saleItemsParams = this.saleItemList.map(item => ({
        order_id: this.orderForm.order_id, // 其实这里假设所有商品的订单号都是同一个
        artwork_id: item.artwork_id,
        sale_price: item.sale_price,
        quantity: item.quantity,
        tax: item.tax,
        total_price: item.total_price
      }));

      // 发送请求
      this.$http.post('/v1/AddSaleItem', saleItemsParams)
          .then(response => {
            if (response.data.state) {
              this.$message({ type: 'success', message: response.data.msg });
              // ... 处理成功响应的后续逻辑
            } else {
              this.$message({ type: 'error', message: response.data.msg });
              // 可能需要进一步处理失败的情况
            }
          })
          .catch(error => {
            // 处理请求发送失败
            this.$message({ type: 'error', message: '网络或服务器错误' });
          });
    },
    viewOrderDetails(row) {
      // 显示销售订单详情的逻辑
      this.dialogTitle = '订单详情';
      this.fillOrderForm(row);
      this.isOrderDialogVisible = true;
    },
    fillOrderForm(order) {
      // 根据订单信息填充表单
      this.orderForm = { ...order };
      this.getSaleItemsDetails(order.order_id);
    },
    getSaleItemsDetails(orderId) {
      this.$http.post('/v1/GetSaleItemsDetailsByOrder', { order_id: orderId }).then(/* ... */);
    },
    addSaleItem() {
      // 判断是否选中了艺术品和客户
      if (this.orderForm.artwork_id && this.orderForm.customer_id) {
        const selectedArtwork = this.artworkList.find(art => art.id === this.orderForm.artwork_id);
        if (!selectedArtwork) {
          this.$message({ type: 'warning', message: '未找到选中的艺术品信息' });
          return;
        }

        // 如果orderForm中没有order_id，则去创建一个新的销售订单
        if (!this.orderForm.order_id) {
          const orderParams = {
            order_number: this.orderForm.order_number,
            customer_id: this.orderForm.customer_id,
            artwork_id: this.orderForm.artwork_id,
            operator: this.orderForm.operator,
            payment_method: this.orderForm.payment_method,
            status: this.orderForm.status,
            note: this.orderForm.note
          };
          this.loading = true;
          this.$http.post('/v1/AddOrder', orderParams).then(response => {
            this.loading = false;
            if (response.data.state) {
              // 将创建成功的订单ID存到orderForm中
              this.orderForm.order_id = response.data.data.order_id;
              // 订单创建成功后，将商品添加到saleItemList中
              this.saleItemList.push({
                artwork_id: this.orderForm.artwork_id,
                artwork_name: selectedArtwork.artwork_name,
                sale_price: '',  // 价格可以预设或由用户输入
                quantity: 1,     // 数量可以预设或由用户输入
                tax: '',         // 税可以预设或由用户输入
                total_price: ''   // 总价可以预设或由用户输入
              });
              this.$message({ type: 'success', message: '商品添加成功' });
            } else {
              this.$message({ type: 'error', message: '订单创建失败，请稍后再试' });
            }
          }).catch(error => {
            this.loading = false;
            this.$message.error('添加销售商品时发生网络错误');
          });
        } else {
          // 如果已有order_id，直接添加商品信息到saleItemList
          this.saleItemList.push({
            artwork_id: this.orderForm.artwork_id,
            artwork_name: selectedArtwork.artwork_name,
            sale_price: '',  // 价格可以预设或由用户输入
            quantity: 1,     // 数量可以预设或由用户输入
            tax: '',         // 税可以预设或由用户输入
            total_price: ''  // 总价可以预设或由用户输入
          });
        }
      } else {
        this.$message({ type: 'warning', message: '请确认艺术品和客户都已选择' });
      }
    },
    deleteOrder(orderId) {
      // 弹出确认框，确认是否删除
      this.$confirm('此操作将永久删除该订单及其所有相关商品记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true;
        // 调用删除订单的接口
        this.$http.post('/v1/DeleteOrder', { order_id: orderId })
            .then(response => {
              this.loading = false;
              if (response.data.state) {
                this.$message({
                  type: 'success',
                  message: '销售订单及其销售商品记录删除成功'
                });
                // 删除后重新获取订单列表
                this.getOrderList();
              } else {
                this.$message({
                  type: 'error',
                  message: response.data.msg
                });
              }
            })
            .catch(error => {
              this.loading = false;
              this.$message.error('删除失败:' + error.message);
            });
      }).catch(() => {
        // 取消删除操作
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新增搜索艺术品的函数
    searchArtwork(query) {
      if (query !== '') {
        this.artworkLoading = true;

        // 这里你应该调用 API，下面是示例的请求格式和实现
        const params = {
          Offset: 1,
          Limit: 10,
          artwork_name: query,
          // 其他参数根据需要添加，如 artist_id, artist_name 等
        };

        // 以下是模拟的 API 请求，实际上应调用 this.$http.post(...)
        this.$http.post('/v1/GetAllArtwork', params)
            .then(response => {
              if (response.data.state) {
                // 更新 artworkList 用于 el-select 显示
                this.artworkList = response.data.data.map(art => ({
                  id: art.id, // 艺术品的 ID
                  artwork_name: art.artwork_name // 艺术品的名称
                }));
              } else {
                // 处理错误，可能显示一个消息提示
              }
            })
            .catch(error => {
              // 处理请求失败的情况
            })
            .finally(() => {
              this.artworkLoading = false;
            });
      }
    },
    // 新增搜索客户的方法
    searchCustomers(query) {
      if (query !== '') {
        this.customerLoading = true;

        const params = {
          Offset: 1,
          Limit: 10,
          name: query,
        };

        // 发送请求获取客户数据
        this.$http.post('/v1/GetAllCustomers', params).then(response => {
          if (response.data.state) {
            this.customersList = response.data.data;
          } else {
            // 处理错误
          }
        }).catch(error => {
          // 处理请求失败
        }).finally(() => {
          this.customerLoading = false;
        });
      }
    },

  }
};
</script>