<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>采集账号管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card class="box-card">
      <!-- 搜索框 -->
      <div style="margin-bottom: 20px;">
        <el-input
            placeholder="搜索账号名称"
            v-model="searchQuery"
            style="width: 400px;"
            @keyup.enter.native="fetchGatherUsers"
            @clear="handleSearchClear"
            clearable
        >
          <el-button slot="append" icon="el-icon-search" @click="fetchGatherUsers"></el-button>
        </el-input>
        <el-button type="primary" @click="showAddGatherUserDialog" style="margin-left: 20px;">新增采集账号</el-button>
      </div>

      <!-- 采集账号列表表格 -->
      <el-table
          :data="gatherUsers"
          border
          style="width: 100%"
          stripe
          highlight-current-row
      >
        <el-table-column prop="username" label="账号名称" sortable></el-table-column>
        <el-table-column prop="login_state" label="登录状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.login_state === 1 ? 'success' : 'danger'">
              {{ scope.row.login_state === 1 ? '已登录' : '未登录' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lock_state" label="账号是否被封">
          <template slot-scope="scope">
            <el-tag :type="scope.row.lock_state === 0 ? 'success' : 'danger'">
              {{ scope.row.lock_state === 0 ? '未封' : '已封' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="258">
          <template slot-scope="scope">
            <el-button size="mini" @click="showEditGatherUserDialog(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteGatherUser(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
          :total="totalGatherUsers"
          :page-size="pageSize"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      ></el-pagination>
    </el-card>

    <!-- 新增采集账号对话框 -->
    <el-dialog title="新增采集账号" :visible.sync="gatherUserDialogVisible" width="30%">
      <el-form :model="gatherUserForm" ref="gatherUserForm" label-width="80px">
        <el-form-item label="账号名称">
          <el-input v-model="gatherUserForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="gatherUserForm.password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="gatherUserDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitGatherUserForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '', // 合并后的搜索条件
      gatherUsers: [],
      // 添加分页控制
      pageSize: 10,
      currentPage: 1,
      totalGatherUsers: 0,
      gatherUserDialogVisible: false, // 控制新增采集账号对话框的显示
      gatherUserForm: {
        id: null, // 用于编辑时存储采集账号ID
        username: '',
        password: '',
        login_state: 0,
        lock_state: 0
      },
      isEditMode: false, // 标识是否为编辑模式
    };
  },
  created() {
    this.fetchGatherUsers();
  },
  methods: {
    // 获取采集账号列表
    fetchGatherUsers() {
      const params = {
        Offset: this.currentPage,
        Limit: this.pageSize,
        username: this.searchQuery,
      };
      this.$http.get('/v1/GatherUserAPI', { params }).then(({ data }) => {
        if (data.state) {
          this.gatherUsers = data.data;
          this.totalGatherUsers = data.count;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 处理分页大小变更
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // 重置为第一页
      this.fetchGatherUsers(); // 刷新列表
    },
    // 处理当前页码变更
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchGatherUsers(); // 刷新列表
    },
    showEditGatherUserDialog(gatherUser) {
      this.isEditMode = true;
      this.gatherUserForm = { ...gatherUser };
      this.gatherUserDialogVisible = true;
    },
    submitGatherUserForm() {
      if (this.isEditMode) {
        this.$http.put('/v1/GatherUserAPI', this.gatherUserForm).then(({ data }) => {
          if (data.state) {
            this.$message.success(data.msg);
            this.gatherUserDialogVisible = false;
            this.fetchGatherUsers();
          } else {
            this.$message.error(data.msg);
          }
        });
      } else {
        this.$http.post('/v1/GatherUserAPI', this.gatherUserForm).then(({ data }) => {
          if (data.state) {
            this.$message.success(data.msg);
            this.gatherUserDialogVisible = false;
            this.fetchGatherUsers();
          } else {
            this.$message.error(data.msg);
          }
        });
      }
    },
    deleteGatherUser(gatherUser) {
      this.$confirm('确认删除该采集账号吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete('/v1/GatherUserAPI', { data: { user_id: gatherUser.id } }).then(({ data }) => {
          if (data.state) {
            this.$message.success(data.msg);
            this.fetchGatherUsers();
          } else {
            this.$message.error(data.msg);
          }
        });
      }).catch(() => {
        this.$message.info('删除操作已取消');
      });
    },
    // 显示新增采集账号对话框
    showAddGatherUserDialog() {
      this.isEditMode = false; // 设置为非编辑模式
      this.gatherUserForm = {
        id: null,
        username: '',
        password: '',
        login_state: 0,
        lock_state: 0
      }; // 重置表单
      this.gatherUserDialogVisible = true; // 显示对话框
    },
    // 搜索框清除内容时的处理函数
    handleSearchClear() {
      this.searchQuery = ''; // 清空搜索条件
      this.fetchGatherUsers(); // 刷新采集账号列表
    },
  }
};
</script>

<style scoped>
/* 自定义样式 */
</style>
