<template>
  <div>
    <!--    面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcom' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/userManagement' }">用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    卡片视图-->
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
          <!--          用户栏搜索-->
          <el-input placeholder="支持搜索 用户名 | 姓名 | 邮箱 | 手机号" v-model="userlist.quy">
            <el-button slot="append" icon="el-icon-search" @click="getuserlist"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="AddUserDialogFormVisible">添加用户</el-button>
          <!--增加用户 二级弹框-->
          <el-dialog title="添加用户信息" :visible.sync="dialogFormVisible" @close="editDialogClosed">
            <el-row>
              <el-col :span="12">
                <div>
                  <el-form label-width="85px" :rules="rules" ref="addUserFormRef" :model="newusername">
                    <el-form-item label="用户名" prop="username">
                      <el-input autocomplete="off" v-model='newusername.username'></el-input>
                    </el-form-item>
                    <el-form-item label="用户昵称">
                      <el-input autocomplete="off" v-model="newusername.real_name"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                      <el-input autocomplete="off" v-model="newusername.email"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone">
                      <el-input autocomplete="off" v-model="newusername.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                      <el-input autocomplete="off" v-model="newusername.password"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="用户余额">-->
<!--                      <el-input autocomplete="off" v-model="newusername.user_balance"></el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item label="备注">
                      <el-input type="textarea" autocomplete="off" v-model="newusername.remark"></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
              <el-col :span="12">
                <div>
                  <span>用户状态</span>
                  <el-radio-group v-model="newusername.enable">
                    <el-radio v-model="newusername.enable" label="1" border>正常</el-radio>
                    <el-radio v-model="newusername.enable" label="0" border>禁用</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <div slot="footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="newuser_cilck">确 定</el-button>
            </div>
          </el-dialog>
        </el-col>
      </el-row>
      <!--      用户表格列表-->
      <el-table :data="userlists" border style="width: 100%" stripe highlight-current-row show-overflow-tooltip clearSort
                max-height="600">
        <el-table-column type="selection" align="center" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column prop="id" label="序号" width="80" align="center" sortable show-overflow-tooltip></el-table-column>
        <el-table-column prop="user_name" label="用户名" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="real_name" label="姓名" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="email" label="邮箱" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center"  width="170" show-overflow-tooltip></el-table-column>
<!--        <el-table-column prop="user_balance" label="余额" align="center" width="110" sortable show-overflow-tooltip></el-table-column>-->
        <el-table-column prop="created_time" label="注册时间" align="center" width="180" sortable show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="备注" align="center" fit show-overflow-tooltip></el-table-column>
        <el-table-column prop="enable" label="状态" align="center" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch v-model="scope.row.enable"
                       :active-value=1
                       :inactive-value=0
                       active-color="#13ce66"
                       inactive-color="#ff4949" @change="userStateChange(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="user_name" align="center" label="操作" width="265">
          <template slot-scope="scope">
            <el-button size="mini" @click="showTransferDialog(scope.row.id)">分配角色</el-button>
            <el-button size="mini" @click="ModifyUserShowEditDialog(scope.row)">修改</el-button>
            <el-button
                v-if="scope.row.user_name === 'admin'"
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)" disabled>删除
            </el-button>
            <el-button
                v-if="scope.row.user_name !== 'admin'"
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)">删除
            </el-button>
            <!--修改用户信息 二级弹框-->
            <el-dialog title="修改用户信息" :visible.sync="ModifyUserInfoVisible">
              <el-row>
                <el-col :span="12">
                  <div>
                    <el-form label-width="85px" :rules="rules">
                      <el-form-item label="用户名">
                        <el-input autocomplete="off" v-model="ModifyUsers.user_name" ></el-input>
                      </el-form-item>
                      <el-form-item label="用户昵称">
                        <el-input autocomplete="off" v-model="ModifyUsers.real_name"></el-input>
                      </el-form-item>
                      <el-form-item label="邮箱">
                        <el-input autocomplete="off" v-model="ModifyUsers.email"></el-input>
                      </el-form-item>
                      <el-form-item label="手机号">
                        <el-input autocomplete="off" v-model="ModifyUsers.phone"></el-input>
                      </el-form-item>
                      <el-form-item label="密码" >
                        <el-input autocomplete="off" v-model="ModifyUsers.password"></el-input>
                      </el-form-item>
                      <el-form-item label="备注">
                        <el-input type="textarea" autocomplete="off" v-model="ModifyUsers.remark"></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span>用户状态</span>
                    <el-radio-group v-model="ModifyUsers.enable">
                      <el-radio :label="1" border>正常</el-radio>
                      <el-radio :label="0" border v-if="scope.row.user_name === 'admin'" >禁用</el-radio>
                      <el-radio :label="0" border v-if="scope.row.user_name !== 'admin'">禁用</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
              </el-row>
              <div slot="footer">
                <el-button @click="ModifyUserInfoVisible = false">取 消</el-button>
                <el-button type="primary" @click="ModifyUserInfoClick">确 定</el-button>
              </div>
            </el-dialog>
          </template>
        </el-table-column>
      </el-table>

      <!--      页码-->
      <el-pagination
          :page-sizes="[10, 20, 50, 100]"
          :page-size="10"
          background
          layout="total,sizes,prev, pager, next,jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="tota">
      </el-pagination>
      <el-dialog title="分配角色" :visible.sync="transferDialogVisible" width="50%">
        <el-transfer
            filterable
            :data="roleList"
            v-model="selectedRoles"
            :titles="['可分配角色', '已分配角色']"
            :render-content="renderFunc">
        </el-transfer>
        <div slot="footer">
          <el-button @click="transferDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="updateUserRoles">确定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import qs from "qs-stringify";

export default {
  data(){
    return{
      userlist:{
        quy:'',
        Limit:10,
        Offset:1,
      },
      newusername:{
        username:'',
        password:'',
        real_name:'',
        email:'',
        phone:'',
        enable:"1",
        user_balance:0,
        remark: null,
      },
      ModifyUsers:{
        user_id: '',
        user_name:'',
        password:'',
        real_name:'',
        email:'',
        phone:'',
        user_balance:'',
        enable:"1",
        remark: null,

      },
      rules: {
        username: [
          {required: true, message: "用户名不能为空", trigger: 'blur'},
          {min: 3, max: 15, message: "用户名3-15位", trigger: 'blur'},
        ],
        password: [
          {required: true, message: "密码不能为空", trigger: 'blur'},
          {min: 6, max: 20, message: "密码6-20位", trigger: 'blur'},
        ],
        email: [
          {required: true, message: "邮箱不能为空", trigger: 'blur'},
          {min: 4, max: 20, message: "邮箱4-20位", trigger: 'blur'},
        ],
        phone: [
          {required: true, message: "手机号不能为空", trigger: 'blur'},
          {min: 11, max: 11, message: "请输入11位手机号", trigger: 'blur'},
        ]
      },
      userlists:[],
      tota:0,
      // 用户状态设置
      DisableUser: {
        username: "",
        state: null
      },
      // 删除用户
      DeleteUser: {
        user_id: ""
      },
      //用户状态默认值
      user_enable: "0",
      //  用户类型，是否是管理员
      user_type: "1",
      //  修改用户按钮
      ModifyUserInfoVisible:false,
      // 添加、删除用户二级弹框取消按钮
      dialogFormVisible: false,

      roleList: [], // 存储角色列表
      userRoles: [], // 存储用户角色
      transferDialogVisible: false, // 控制穿梭框弹窗的显示
      selectedRoles: [], // 穿梭框中选中的角色
      selectedUserId: null, // 当前选中的用户ID
      loading: false, // 控制加载状态
    }
  },

  created() {
    this.getuserlist()
  },
  methods:{
    async getuserlist() {
      try {
        this.loading = true; // 开始加载
        const response = await this.$http.post('/v1/GetUserList', this.userlist);
        this.loading = false; // 结束加载
        const { data } = response;
        if (data && data.state) {
          this.userlists = data.data;
          this.tota = data.count;
        } else {
          this.$message.error('用户列表获取失败');
        }
      } catch (error) {
        this.loading = false; // 出错时结束加载
        this.$message.error('请求用户列表发生错误');
      }
    },
    //分页上一页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.userlist.Limit = val
      this.$http.post('/v1/GetUserList',this.userlist).then(data => {
        // console.logs(data.data.data)
        // console.logs(data.data.total_count)
        this.userlists = data.data["data"]
        this.tota = data.data["count"]
      })
    },
    //分页下一页
    handleCurrentChange(val) {
      this.userlist.Offset = val
      console.log(`当前页: ${val}`);
      this.$http.post('/v1/GetUserList',this.userlist).then(data => {
        this.userlists = data.data["data"]
        this.tota = data.data["count"]
      })
    },
    // 添加用户确认按钮
    newuser_cilck() {
      // 进行表单校验
      this.$refs.addUserFormRef.validate((valid) => {
        if (valid) {
          // 如果校验通过，则执行添加用户操作
          this.$http.post('/v1/reg', this.newusername).then(data => {
            if (data.data["state"] === false) {
              this.$message.error(data.data['msg']);
            } else {
              this.$message.success("添加用户成功");
              // this.getuserlist();
              this.dialogFormVisible = false; // 关闭弹窗
            }
          });
        } else {
          // 如果校验未通过，则不执行添加用户操作
          this.$message.error('输入的内容未通过校验，请检查后再试');
        }
      });
    },
    //  修改用户确认按钮
    ModifyUserInfoClick(){
      this.$http.post('/v1/ModifyUserInfo',this.ModifyUsers).then(data => {
        if (data.data["msg"] === "修改成功"){
          this.$message.success("修改成功");
          this.getuserlist()
        }else {
          this.$message.error(data.data['msg']);
        }
      })
    },
    //监听switch开关状态的改变
    userStateChange(userinfo){
      console.log(userinfo);
      this.DisableUser.state = userinfo.enable
      this.DisableUser.username = userinfo.user_name
      this.$http.put('/v1/DisableUser',this.DisableUser).then(data => {
        console.log(data.data["msg"])
        if (data.data["msg"] === "修改成功"){
          this.$message.success('修改成功');
        }else {
          return this.$message.error(data.data["msg"]);
        }
      })
    },
    //删除按钮
    handleDelete(userinfo){
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 设置 user_id
        this.DeleteUser.user_id = userinfo.id
        this.$http.post('/v1/DeleteUser', this.DeleteUser).then(data => {
          console.log(data.data["msg"])
          if (data.data["msg"] === "删除成功"){
            this.$message.success('用户删除成功');
            this.getuserlist()
          }else {
            this.$message.error(data.data["msg"]);
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //获取需要修改的用户信息，并重新赋值
    ModifyUserShowEditDialog(userinfo){
      this.ModifyUserInfoVisible = true;
      this.ModifyUsers.user_id = userinfo.id;
      this.ModifyUsers.user_name = userinfo.user_name;
      this.ModifyUsers.email = userinfo.email;
      this.ModifyUsers.phone = userinfo.phone;
      this.ModifyUsers.real_name = userinfo.real_name;
      this.ModifyUsers.user_balance = userinfo.user_balance;
      this.ModifyUsers.enable = userinfo.enable;
      this.ModifyUsers.remark = userinfo.remark;

    },
    //新增用户二级菜单弹出状态
    AddUserDialogFormVisible(){
      this.dialogFormVisible = true;
    },
    //关闭新增用户二级创建时清空输入框数据
    editDialogClosed(){
      this.$refs.editUserFormRef.resetFields();
    },
    async getAllRoles(){
      const response = await this.$http.get('/v1/Role?Offset=1&Limit=1000');
      if(response.data.state){
        this.roleList = response.data.data.map(role => ({
          key: role.id,
          label: role.name,
        }));
      } else {
        throw new Error(response.data.msg);
      }
    },
    async getUserRoles(userId){
      const response = await this.$http.get(`/v1/UserRole?user_id=${userId}`);
      if(response.data.state){
        this.userRoles = response.data.data.roles.map(role => role.role_id);
        this.selectedRoles = [...this.userRoles]; // 初始化穿梭框选中的角色
      } else {
        throw new Error(response.data.msg);
      }
    },
    async showTransferDialog(userId){
      try {
        this.selectedUserId = userId;
        // 先获取所有角色列表
        await this.getAllRoles();
        // 然后获取用户的角色
        await this.getUserRoles(userId);
        // 显示穿梭框弹窗
        this.transferDialogVisible = true;
      } catch (error) {
        // 如果请求失败，显示错误消息
        this.$message.error('获取角色列表或用户角色失败');
      }
    },
    updateUserRoles(){
      const payload = {
        user_id: this.selectedUserId,
        new_role_ids: this.selectedRoles
      };
      this.$http.put('/v1/UserRole', payload).then(response => {
        if(response.data.state){
          this.$message.success(response.data.msg);
          this.transferDialogVisible = false; // 关闭穿梭框弹窗
          this.selectedRoles = []; // 清空选中的角色
        } else {
          this.$message.error(response.data.msg);
        }
      });
    },
    renderFunc(h, option) {
      return h('span', { class: 'transfer-item-label' }, option.label);
    },

  }
}
</script>

<style scoped>

</style>
